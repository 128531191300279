import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { Suspense } from "react";

export default function RequiredAuth() {
  const { oktaAuth, authState } = useOktaAuth();
  const [isInitiating, setIsInitiating] = useState(false);

  useEffect(() => {
    // Only proceed if we have an authState
    if (!authState) {
      return;
    }

    // Handle unauthenticated state
    if (!authState.isAuthenticated && !isInitiating) {
      // Clear any potentially corrupted PKCE state
      try {
        window.localStorage.removeItem(`okta-cache-storage`);
        window.sessionStorage.removeItem(`okta-cache-storage`);
      } catch (e) {
        console.error('Error clearing storage:', e);
      }

      // Prevent multiple auth flows
      setIsInitiating(true);
      
      try {
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        
        // Small delay to ensure storage is cleared
        setTimeout(() => {
          oktaAuth.signInWithRedirect().catch(err => {
            console.error('Error during sign in:', err);
            setIsInitiating(false);
          });
        }, 50);
      } catch (err) {
        console.error('Error initiating auth flow:', err);
        setIsInitiating(false);
      }
    }
    
    // Reset initiating flag when authenticated
    if (authState.isAuthenticated) {
      setIsInitiating(false);
    }
  }, [oktaAuth, authState, isInitiating]);

  if (!authState || !authState.isAuthenticated) {
    return (<h3 id='loading-icon'>Authenticating...</h3>);
  }

  return (
    <Suspense fallback={<div>Loading content...</div>}>
      <Outlet />
    </Suspense>
  );
}