import { trackEvent } from "../../../applicationInsights/appInsights";
import AppTrackingEvents from "../../../applicationInsights/eventConstants";


function trackEditPointEvent(field, oldValue, newValue, videoProcessId, activePoint, currentPlayerTime, authState) {
  if (oldValue === newValue) {
    return;
  }
  trackEvent(AppTrackingEvents.EDITING_POINT_ACTION, {
    videoProcessId: videoProcessId,
    user: authState.idToken.claims.preferred_username,
    event: field,
    initialValue: oldValue,
    newValue: newValue,
    pointId: activePoint.id,
    timestamp: new Date().toISOString(),
    pointScore: activePoint.score,
    currentTime: currentPlayerTime,
  });
}

export { trackEditPointEvent };
