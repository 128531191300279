class Game {
    static points = ["00", "15", "30", "40", "AD"];

    constructor(startingScore = "00-00", tiebreak = false, tiebreakPoints = 7, advantage = true) {
        let [p1, p2] = startingScore.split("-");
        this.winner = null;
        this.tiebreak = tiebreak;
        this.tiebreakPoints = tiebreakPoints;
        this.advantage = advantage;
        if (tiebreak) {
            this.scores = [{ "p1": Number(p1), "p2": Number(p2), "winner": null }];
        } else {
            this.scores = [{ "p1": Game.points.indexOf(p1), "p2": Game.points.indexOf(p2), "winner": null }];
        }
    }

    get currentScore() {
        return this.scores[this.scores.length - 1];
    }

    get score() {
        // String representation of the current score "15-0"
        const currentScore = this.currentScore;
        if (this.tiebreak) {
            return `${currentScore["p1"]}-${currentScore["p2"]}`;
        }
        return `${Game.points[currentScore["p1"]]}-${Game.points[currentScore["p2"]]}`;
    }

    increment(player) {
        const currentScore = this.scores[this.scores.length - 1];

        const winnerPlayer = player;
        const otherPlayer = player == "p1" ? "p2" : "p1";

        let winnerScore = currentScore[winnerPlayer]
        let otherScore = currentScore[otherPlayer]
        let newScore = null;

        // Tiebreak game
        if (this.tiebreak) {
            // Increment the point winner score
            let newWinnerScore = winnerScore + 1;

            if (newWinnerScore >= this.tiebreakPoints && newWinnerScore - otherScore >= 2) {
                // The point winner wins the game
                newScore = { "p1": 0, "p2": 0, "winner": winnerPlayer };
                this.winner = player;

            } else {
                newScore = { [winnerPlayer]: newWinnerScore, [otherPlayer]: otherScore, "winner": winnerPlayer };
            }

            if (this.winner == null) {
                this.scores.push(newScore)
            }
            return newScore;
        }

        // The point winner player is at 40
        if (winnerScore == 3) {

            // The opponent is at 40 (deuce)
            if (otherScore == 3) {
                // Point winner goes to AD
                if (!this.advantage) {
                    newScore = { [winnerPlayer]: 0, [otherPlayer]: 0, "winner": winnerPlayer };
                    this.winner = player
                } else {
                    newScore = { [winnerPlayer]: 4, [otherPlayer]: 3, "winner": winnerPlayer };
                }

                // The opponent is at AD
            } else if (otherScore == 4) {
                // Both players go back to 40
                newScore = { [winnerPlayer]: 3, [otherPlayer]: 3, "winner": winnerPlayer };

                // The opponent is on 0, 15 or 30
            } else {
                // Point winner wins the game
                newScore = { [winnerPlayer]: 0, [otherPlayer]: 0, "winner": winnerPlayer };
                this.winner = winnerPlayer
            }

            // The point winner player is at AD
        } else if (winnerScore == 4) {
            // Point winner wins the game
            newScore = { "p1": 0, "p2": 0, "winner": winnerPlayer };
            this.winner = player

            // The point winner player is at 0, 15 or 30
        } else {
            // Increment the point winner score
            newScore = { [winnerPlayer]: winnerScore + 1, [otherPlayer]: otherScore, "winner": winnerPlayer };
        }

        // Only add the score if the game is not won
        if (this.winner == null) {
            this.scores.push(newScore)
        }
        return newScore
    }


}
class Set {
    constructor(startingScore = "0-0 00-00", gameCount = 6, advantage = true) {
        let [setScore, gameScore] = startingScore.split(" ");
        let [p1, p2] = setScore.split("-");

        this.gameCount = gameCount;

        let tiebreak = false;
        let tiebreakPoints = 7;
        if (this.gameCount == 1) {
            tiebreak = true;
            tiebreakPoints = 10;
        }
        this.advantage = advantage;
        this.games = [{ "p1": Number(p1), "p2": Number(p2), "game": new Game(gameScore, tiebreak, tiebreakPoints, this.advantage) }];
    }

    get currentGame() {
        return this.games[this.games.length - 1];
    }

    get score() {
        // String representation of the current score "6-4"
        let currentGame = this.currentGame;
        return `${currentGame["p1"]}-${currentGame["p2"]}`;
    }


    increment(player) {
        let currentGame = this.currentGame;

        const winnerPlayer = player;
        const otherPlayer = player == "p1" ? "p2" : "p1";

        let winnerScore = currentGame[winnerPlayer];
        let otherScore = currentGame[otherPlayer];

        // Increment the point winner score
        let newGameScore = currentGame["game"].increment(player);

        let newSetScore = null;

        // The point winner wins the game
        if (newGameScore["p1"] == 0 && newGameScore["p2"] == 0) {
            // Increment the point winner set score
            let newWinnerScore = winnerScore + 1;

            // The point winner wins the set
            if (newWinnerScore >= this.gameCount && newWinnerScore - otherScore >= 2 || currentGame["game"].tiebreak) {
                newSetScore = { "p1": 0, "p2": 0, "game": new Game("00-00", false, 7, this.advantage) };
                this.winner = player;

            } else {
                // Check for tie-break game
                let tiebreak = false;
                if (newWinnerScore == this.gameCount && otherScore == this.gameCount) {
                    tiebreak = true;
                }
                newSetScore = { [winnerPlayer]: newWinnerScore, [otherPlayer]: otherScore, "game": new Game("00-00", tiebreak, 7, this.advantage) };

            }
        } else {
            newSetScore = { "p1": currentGame["p1"], "p2": currentGame["p2"], "game": currentGame["game"] };
        }


        // Only add the score if the set is not won and the game is won
        if (this.winner == null && currentGame["game"].winner != null) {
            this.games.push(newSetScore)
        }
        return newSetScore;
    }
}
class Match {
    constructor(config, startingScore = "0-0 0-0 00-00") {
        let [matchScore, setScore, gameScore] = startingScore.split(" ");
        let [p1, p2] = matchScore.split("-");

        // config = { "advantage": "true or false", "grouping": "comp2 or dev1 or dev2" }
        this.config = config;

        this.gamesPerSet = 6;
        if (this.config["grouping"] == "BestOfThreeFastFourSets") {
            this.gamesPerSet = 4;
        } else if (this.config["grouping"] == "EightGameProSet") {
            this.gamesPerSet = 7;
        }
        this.advantage = this.config["advantage"];
        this.sets = [{ "p1": Number(p1), "p2": Number(p2), "set": new Set(`${setScore} ${gameScore}`, this.gamesPerSet, this.advantage) }];
    }

    get currentSet() {
        return this.sets[this.sets.length - 1];
    }

    get score() {
        // String representation of the current score "2-1"
        let currentSet = this.currentSet;
        return `${currentSet["p1"]}-${currentSet["p2"]}`;
    }


    increment(player) {
        let currentSet = this.currentSet;

        const winnerPlayer = player;
        const otherPlayer = player == "p1" ? "p2" : "p1";

        let winnerScore = currentSet[winnerPlayer];
        let otherScore = currentSet[otherPlayer];

        // Increment the point winner score
        let newSetScore = currentSet["set"].increment(player);

        let newMatchScore = null;
        // The point winner wins the set
        if (newSetScore["p1"] == 0 && newSetScore["p2"] == 0 && currentSet["set"].winner != null) {
            // Increment the point winner match score
            let newWinnerScore = winnerScore + 1;

            let gameCount = this.gamesPerSet;
            if (this.config["grouping"] == "BestOfThreeTenPointFinalSetTiebreak" || this.config["grouping"] == "BestOfThreeFastFourSets") {
                if (newWinnerScore == 1 && otherScore == 1) {
                    gameCount = 1;
                }
            }
            newMatchScore = { [winnerPlayer]: winnerScore + 1, [otherPlayer]: otherScore, "set": new Set("0-0 00-00", gameCount, this.advantage) };
        } else {
            newMatchScore = { "p1": currentSet["p1"], "p2": currentSet["p2"], "set": currentSet["set"] };
        }

        // Only add the score if the match is not won and the set is won
        if (currentSet["set"].winner != null) {
            this.sets.push(newMatchScore)
        }
        return newMatchScore;
    }
}

export const updateScoreSequence = (config, startingScore, pointWinnerSequence) => {
    // Create a new match instance
    const match = new Match(config, startingScore);
    let updatedPointsScores = [startingScore];

    // Simulate the match by incrementing points based on the sequence
    for (let i = 0; i < pointWinnerSequence.length; i++) {
        const winner = pointWinnerSequence[i];
        match.increment(winner);
        const matchScore = match.score;

        const currentSet = match.currentSet["set"]
        const setScore = currentSet.score;

        const currentGame = currentSet.currentGame["game"]
        const gameScore = currentGame.score

        updatedPointsScores.push(`${matchScore} ${setScore} ${gameScore}`);
    }
    return updatedPointsScores;
}