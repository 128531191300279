import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";


export const imgaSlice = createApi({
    reducerPath: "imgaapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/imga`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getVideosScraped: builder.query({
            query: ({batch}) => `/videos-scraped?batch=${batch}`,
        }),
        getVideosStatus: builder.query({
            query: ({batch}) => `/videos-status?batch=${batch}`,
        }),
        getVideosCvVersions: builder.query({
            query: ({batch}) => `/videos-cv-versions?batch=${batch}`,
        }),
        getVideosCodingStatus: builder.query({
            query: ({batch}) => `/videos-coding-status?batch=${batch}`,
        }),
        getCodersCodingStatus: builder.query({
            query: ({batch}) => `/coders-coding-status?batch=${batch}`,
        }),
        getVideoAppSummary: builder.query({
            query: ({batch}) => `/video-app-summary?batch=${batch}`,
        }),
        getPlayerErrorSummary: builder.query({
            query: ({batch}) => `/player-error-matches-summary?batch=${batch}`,
        }),
        getIMGAPlayers: builder.query({
            query: () => `/players`,
        })
    }),
});

export const {
    useGetVideosScrapedQuery,
    useGetVideosStatusQuery,
    useGetVideosCvVersionsQuery,
    useGetVideosCodingStatusQuery,
    useGetCodersCodingStatusQuery,
    useGetVideoAppSummaryQuery,
    useGetPlayerErrorSummaryQuery,
    useGetIMGAPlayersQuery
} = imgaSlice;