import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const editorTimerSlice = createApi({
    reducerPath: "timerapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/editortimer`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTimerStatus: builder.query({
            query: ({ videoProcessId }) => ({
                url: `/status`,
                params: { videoProcessId },
            }),
        }),
        startTimer: builder.mutation({
            query: ({ videoProcessId }) => ({
                url: `/start`,
                method: "POST",
                params: { videoProcessId },
            }),
        }),
        stopTimer: builder.mutation({
            query: ({ videoProcessId }) => ({
                url: `/stop`,
                method: "POST",
                params: { videoProcessId },
            }),
        }),
        sendHeartbeat: builder.mutation({
            query: ({ videoProcessId }) => ({
                url: `/heartbeat`,
                method: "POST",
                params: { videoProcessId },
            }),
        }),
    }),
});

export const {
    useGetTimerStatusQuery,
    useStartTimerMutation,
    useStopTimerMutation,
    useSendHeartbeatMutation,
} = editorTimerSlice;