
import { useSelector, useDispatch } from "react-redux";
import { selectActivePoint, editActivePoint, selectMatch, selectCurrentPlayerTime, selectIsImg } from "../store/editorSlice";
import ButtonGroup from "../../../components/ButtonGroup";
import { serviceLocationTypeOptions, shotPlacementTypeOptions } from "../Utils/options";
import NumberInput from "../../../components/NumberInput";
import Switch from "../../../components/Switch";

import { rallyLengthChanged } from "../Utils/pointUtils";
import { addSecondServe } from "../Utils/serveUtils";
import { addReturn } from "../Utils/shotUtils";
import * as constants from "../Utils/constants";
import { trackEditPointEvent } from "../Utils/trackEditPointUtils";
import { useOktaAuth } from "@okta/okta-react";

const EditServe = ({ shot, videoProcessId }) => {
    const dispatch = useDispatch();
    const activePoint = useSelector(selectActivePoint);
    const match = useSelector(selectMatch);
    const currentPlayerTime = useSelector(selectCurrentPlayerTime);
    const { authState, oktaAuth } = useOktaAuth();
    const isImg = useSelector(selectIsImg);

    const speedUnit = match.speedUnit == constants.KMH_SPEED_UNIT ? "km/h" : "mph";

    let servePlacementValue = (!shot.out && !shot.net) ? constants.IN_SHOT_PLACEMENT : shot.out ? constants.OUT_SHOT_PLACEMENT : constants.NET_SHOT_PLACEMENT;

    const isFirstServeShot = shot.shotOrderId == constants.FIRST_SERVE_SHOT_ORDER_ID;
    const isSecondServeShot = shot.shotOrderId == constants.SECOND_SERVE_SHOT_ORDER_ID;
    const isAce = activePoint.isAce && ((isFirstServeShot && activePoint.serveTypeId == constants.FIRST_SERVE) || isSecondServeShot);
    const label = isFirstServeShot ? "1st Serve" : "2nd Serve";

    const optionChangedHandler = (field, value) => {
        trackEditPointEvent(`${field}-${shot.shotOrderId}`, shot[field], value, videoProcessId, activePoint, currentPlayerTime, authState);
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == shot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const updateServeType = (updatedShot, servePlacementValue, updatedPoint) => {
        updatedShot.strokeTypeId = constants.NORMAL_STROKE_TYPE;
        //first serve in
        if (isFirstServeShot && servePlacementValue === constants.IN_SHOT_PLACEMENT) {
            updatedPoint.serveTypeId = constants.FIRST_SERVE;
            // Not Ace
            if (!updatedPoint.isAce) {
                updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId !== 1);
                addReturn(updatedPoint);
                updatedPoint = rallyLengthChanged(updatedPoint);
            }
            // Ace
            else {
                updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId == constants.FIRST_SERVE_SHOT_ORDER_ID);
                updatedShot.strokeTypeId = constants.ACE_STROKE_TYPE;
                updatedPoint.rallyEndingShotTypeId = constants.WINNER_RALLY_ENDING_SHOT_TYPE;
            }
        }
        // first serve out
        else if (isFirstServeShot && servePlacementValue !== constants.IN_SHOT_PLACEMENT) {
            updatedPoint.serveTypeId = constants.SECOND_SERVE;
            addSecondServe(updatedPoint);
            // Not Ace
            if (!updatedPoint.isAce) {
                addReturn(updatedPoint);
                updatedPoint = rallyLengthChanged(updatedPoint);
            }
        }
        //second serve in
        else if (isSecondServeShot && servePlacementValue === constants.IN_SHOT_PLACEMENT) {
            updatedPoint.serveTypeId = constants.SECOND_SERVE;
            // Not Ace
            if (!updatedPoint.isAce) {
                addReturn(updatedPoint);
                updatedPoint = rallyLengthChanged(updatedPoint);
            }
            // Ace
            else {
                updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.SECOND_SERVE_SHOT_ORDER_ID);
                updatedShot.strokeTypeId = constants.ACE_STROKE_TYPE;
                updatedPoint.rallyEndingShotTypeId = constants.WINNER_RALLY_ENDING_SHOT_TYPE;
            }
        }
        //second serve out (double fault)
        else if (isSecondServeShot && servePlacementValue !== constants.IN_SHOT_PLACEMENT) {
            updatedPoint.serveTypeId = constants.DOUBLE_FAULT;
            updatedPoint.rallyLength = 0;
            updatedPoint.isAce = false;
            updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.SECOND_SERVE_SHOT_ORDER_ID);
            updatedPoint.rallyEndingShotTypeId = constants.UNFORCED_RALLY_ENDING_SHOT_TYPE
            updatedShot.strokeTypeId = constants.DOUBLE_FAULT_STROKE_TYPE;
        }
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const servePlacementChangedHandler = (field, value, newPoint) => {
        trackEditPointEvent(field, servePlacementValue, value, videoProcessId, activePoint, currentPlayerTime, authState);
        const updatedShot = { ...shot };
        switch (value) {
            case constants.IN_SHOT_PLACEMENT:
                updatedShot.out = false;
                updatedShot.net = false;
                break;
            case constants.OUT_SHOT_PLACEMENT:
                updatedShot.out = true;
                updatedShot.net = false;
                break;
            case constants.NET_SHOT_PLACEMENT:
                updatedShot.out = false;
                updatedShot.net = true;
                break;
            default:
                break;

        }
        var updatedPoint = newPoint ?? { ...activePoint };
        updateServeType(updatedShot, value, updatedPoint);
    }

    const aceChangedHandler = (field, value) => {
        trackEditPointEvent(field, isAce, value, videoProcessId, activePoint, currentPlayerTime, authState);
        let updatedPoint = { ...activePoint, isAce: value };
        if (value) {
            updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.SECOND_SERVE_SHOT_ORDER_ID);
            updatedPoint.rallyLength = 1;
            servePlacementChangedHandler("servePlacement", constants.IN_SHOT_PLACEMENT, updatedPoint);
        } else {
            addReturn(updatedPoint);
            updatedPoint = rallyLengthChanged(updatedPoint);
            dispatch(editActivePoint(updatedPoint));
        }
    }

    return (
        <div className="d-flex flex-column mr-1 max-w-50">
            <div className="d-flex align-items-center justify-content-between mb-1">
                <span>{label}</span>
                <div className="d-flex align-items-center">
                    <span className="mr-1">Ace</span>
                    <Switch field={`ace-${shot.shotOrderId}`} value={isAce} onChanged={aceChangedHandler} />
                </div>
            </div>
            {!isImg && !match.disableServeSpeeds && <div className="d-flex flex-row align-items-center mb-1">
                <NumberInput field="speed" value={shot.speed} onNumberChanged={optionChangedHandler} />
                <span className="ml-1">{speedUnit}</span>
            </div> }
            <div className="mb-1">
                <ButtonGroup field={`servePlacement-${shot.shotOrderId}`} value={servePlacementValue} options={shotPlacementTypeOptions} onOptionChanged={servePlacementChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="serviceLocationId" value={shot.serviceLocationId} options={serviceLocationTypeOptions} onOptionChanged={optionChangedHandler} />
            </div>
        </div>
    );
}

export default EditServe;