import { useSelector } from "react-redux";
import { selectActivePoint } from "../store/editorSlice";
import EditLastShots from "../EditShot/EditLastShots";
import * as constants from "../Utils/constants";

const EditGS = ({ videoProcessId }) => {
    const activePoint = useSelector(selectActivePoint);

    const returnShot = activePoint.shots?.find((s) => s.shotOrderId === constants.RETURN_SHOT_ORDER_ID);
    const servePlusOneShot = activePoint.shots?.find((s) => s.shotOrderId === constants.SERVE_PLUS_ONE_SHOT_ORDER_ID);
    const secondToLastShot = activePoint.shots?.find((s) => s.shotOrderId === constants.SECOND_TO_LAST_SHOT_ORDER_ID);
    const lastShot = activePoint.shots?.find((s) => s.shotOrderId === constants.LAST_SHOT_ORDER_ID);
    
    return (
        <>
            {secondToLastShot && servePlusOneShot && (<EditLastShots shot={servePlusOneShot} videoProcessId={videoProcessId} />)}
            {!servePlusOneShot && returnShot && (<EditLastShots shot={returnShot} videoProcessId={videoProcessId} />)}
            {servePlusOneShot && !secondToLastShot && !lastShot && returnShot && (<EditLastShots shot={returnShot} videoProcessId={videoProcessId} />)}

            {servePlusOneShot && (!secondToLastShot || !lastShot) && (<EditLastShots shot={servePlusOneShot} videoProcessId={videoProcessId} />)}
            {secondToLastShot && (<><hr className="w-75" /><EditLastShots shot={secondToLastShot} videoProcessId={videoProcessId} /></>)}
            {lastShot && (<><hr className="w-75" /><EditLastShots shot={lastShot} videoProcessId={videoProcessId} /></>)}
        </>
    );    
}

export default EditGS;