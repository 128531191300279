import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetCvMatchQuery } from "../api/snapshotsSlice";
import { selectEditMode, setMatch, selectMatch, selectShowPointsNavigation, setIsImg } from "./store/editorSlice";
import { selectIsAdmin, selectIsCV, selectUser, selectIsCoder } from "../auth/authSlice";

import EditPoint from "./EditPoint/EditPoint";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import VideoPlayer from "./VideoPlayer/videoPlayer";
import PointsList from "./PointsList/PointsList";
import Spinner from "../../UI/Spinner";
import ExitEditorModal from "./ExitEditorModal";
import EditMatch from "./EditMatch";
import ErrorsList from "./EditPoint/ErrorsList";
import ResetMatchScores from "./ResetMatchScores";
import SwitchServer from "./SwitchServer";
import MatchDetails from "./MatchDetails";

const EditorPage = () => {
  const [searchParams] = useSearchParams();
  const videoProcessId = searchParams.get('videoProcessId');
  const snapshot = searchParams.get('snapshot');
  const isImg = searchParams.get('isImg') === 'true';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsImg(isImg));
  }, [isImg, dispatch]);

  const isAdmin = useSelector(selectIsAdmin);
  const isCV = useSelector(selectIsCV);
  const isCoder = useSelector(selectIsCoder);

  const user = useSelector(selectUser);
  const editMode = useSelector(selectEditMode);
  const showPointsNavigation = useSelector(selectShowPointsNavigation);

  const match = useSelector(selectMatch);

  const { data, isLoading, error, status } = useGetCvMatchQuery({ videoProcessId, snapshotId: snapshot ?? null });

  useEffect(() => {
    if (data) {
      dispatch(setMatch(data));
    }
  }, [data]);

  if (!user || isLoading) return <Spinner />;
  else if (!isAdmin && !isCV && !isCoder) return <NotAuthorizedPage />;

  if (error)
    return (
      <div>
        {status} {error.status} {JSON.stringify(error.error)}
      </div>
    );

  return (
    <div className="row flex-nowrap">
      {
        editMode && <ExitEditorModal />
      }
      {
        editMode && (<div className={`${showPointsNavigation ? "col-lg-3" : "col-lg-4"} mb-3`}>
          <div className="d-flex flex-column mb-2" >
            {data.isHandednessUpdated && (
              <div className="alert alert-warning" role="alert">
                Player handedness has been updated. Please generate the new snapshot.
              </div>
            )}
            <EditMatch videoProcessId={videoProcessId} snapshotId={snapshot} />
            <div className="row mt-2 mx-0">
              <SwitchServer />
              <ResetMatchScores />
            </div>
          </div>
          <EditPoint videoProcessId={videoProcessId} snapshotId={snapshot} />
        </div>
        )
      }
      <div className={`${editMode ? showPointsNavigation ? "col-md-6" : "col-md-8" : "col-md-9"} mb-3`}>
        <MatchDetails match={match} />
        <VideoPlayer videoProcessId={videoProcessId} />
        {editMode && <ErrorsList />}
      </div>
      {match && showPointsNavigation && <PointsList match={match} videoProcessId={videoProcessId} />}
    </div >
  );
}

export default EditorPage;
