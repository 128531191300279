import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsPlaying,
  selectMatch,
  selectEditMode,
  selectActivePoint,
  setCurrentPlayerTime,
  selectReplay,
  selectIsPlayingFromEnd,
  setIsPlaying,
  toggleShowPointsNavigation,
  selectShowPointsNavigation
} from "../store/editorSlice";
import { IconArrowForwardUp, IconArrowBackUp, IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import { trackEvent } from "../../../applicationInsights/appInsights";
import AppTrackingEvents from "../../../applicationInsights/eventConstants";
import { useOktaAuth } from "@okta/okta-react";
import { EDITOR_TRACKING_EVENTS } from "../Utils/constants.js";

export default function VideoPlayer({videoProcessId}) {
  const dispatch = useDispatch();

  const match = useSelector(selectMatch);
  const activePoint = useSelector(selectActivePoint);

  const isPlaying = useSelector(selectIsPlaying);
  const isPlayingFromEnd = useSelector(selectIsPlayingFromEnd);
  const replayTriggered = useSelector(selectReplay);
  const editMode = useSelector(selectEditMode);
  const showPointsNavigation = useSelector(selectShowPointsNavigation);
  const { authState, oktaAuth } = useOktaAuth();

  const [link, setLink] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const playerRef = useRef();
  const isPlayingRef = useRef(isPlaying);

  useEffect(() => {
    if (isReady && activePoint) {
      playerRef.current.seekTo(activePoint.startTime);
      dispatch(setCurrentPlayerTime(activePoint.startTime));
    }
  }, [isReady]);

  useEffect(() => {
    setLink(match?.videoLink || "");
    playerRef.current?.seekTo(activePoint?.startTime);
  }, [match?.videoLink, activePoint?.id, replayTriggered, playerRef.current]);

  useEffect(() => {
    if (isPlayingFromEnd)
      playerRef.current.seekTo(activePoint.endTime - 3);
  }, [isPlayingFromEnd]);

  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!playerRef.current) return;

      if (document.activeElement.tagName === 'INPUT') return;

      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === ' ') {
        event.preventDefault();
        event.stopPropagation();
      }

      const { key } = event;

      if (key === 'ArrowRight') {
        seekForward();
      } else if (key === 'ArrowLeft') {
        seekBackward();
      }
      else if (key === ' ') {
        dispatch(setIsPlaying(!isPlayingRef.current));
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function HandlePlay() {
    dispatch(setIsPlaying(true));
    trackVideoEvent(EDITOR_TRACKING_EVENTS.PLAY);
  }

  function HandlePause() {
    dispatch(setIsPlaying(false));
    trackVideoEvent(EDITOR_TRACKING_EVENTS.PAUSE);
  }

  function trackVideoEvent(event) {
    trackEvent(AppTrackingEvents.EDITING_POINT_ACTION, {
      pointId: activePoint.id,
      event: event,
      user: authState.idToken.claims.preferred_username,
      videoProcessId: videoProcessId,
      pointScore: activePoint.score,
      currentTime: playerRef.current.getCurrentTime(),
      timestamp: new Date().toISOString(),
    });
  }

  function HandleProgress(currentTime) {
    if (!isPlaying) {
      return;
    }
    if (currentTime > activePoint.endTime) {
      dispatch(setIsPlaying(false));
      if (isPlayingFromEnd) {
        playerRef.current.seekTo(activePoint.endTime - 3);
        currentTime = activePoint.endTime - 3;
      } else {
        playerRef.current.seekTo(activePoint.startTime);
        currentTime = activePoint.startTime;
      }
    }
    dispatch(setCurrentPlayerTime(currentTime));
  }

  const seekForward = () => {
    trackVideoEvent(EDITOR_TRACKING_EVENTS.SEEK_FORWARD);
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 2);
    dispatch(setCurrentPlayerTime(playerRef.current.getCurrentTime()));
  };

  const seekBackward = () => {
    trackVideoEvent(EDITOR_TRACKING_EVENTS.SEEK_BACKWARD);
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 2);
    dispatch(setCurrentPlayerTime(playerRef.current.getCurrentTime()));
  };

  const changePlaybackRate = (rate) => {
    setPlaybackRate(rate);
    trackVideoEvent(`${EDITOR_TRACKING_EVENTS.CHANGED_PLAYBACK_RATE}_${rate}`);
  };

  let config = { file: { attributes: { controlsList: "nodownload", tabIndex: '-1' } } };

  if (!match) {
    return (
      <div className="card">
        <div className="card-header"></div>
        <div className="card-body bg-light">
          <div className="row text-black justify-content-center no-gutters">
            <h5>No Video Selected</h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex row align-items-center justify-content-center">
          <h5 className={(!showPointsNavigation && editMode) ? "ml-auto" : ""}>
            {match.name}
          </h5>
          {!showPointsNavigation && editMode &&
            <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle ml-auto" type="button"
              onClick={() => dispatch(toggleShowPointsNavigation())}>
              <span className="navbar-toggle-icon">
                <span className="toggle-line">
                </span>
              </span>
            </button>}
        </div>
      </div>
      <div className="card-body bg-light pb-2">
        <div style={wrapperStyle}>
          <ReactPlayer
            ref={playerRef}
            config={config}
            playing={isPlaying}
            onPlay={() => HandlePlay()}
            onPause={() => HandlePause()}
            onProgress={(e) => HandleProgress(e.playedSeconds)}
            onReady={() => setIsReady(true)}
            controls={true}
            url={link}
            width="100%"
            height="100%"
            playbackRate={playbackRate}
          />
        </div>
        <div className="d-flex align-items-center">
          <div className="flex-fill col d-flex justify-content-end pr-0">
            <button className="btn btn-sm btn-dark mr-1" onClick={seekBackward} data-toggle="tooltip" data-placement="bottom" title="Seek Back 2 sec">
              <IconArrowBackUp />
            </button>
          </div>
         
          <div className="flex-auto">
            <button className="btn btn-sm btn-dark mr-1" onClick={() => dispatch(setIsPlaying(!isPlaying))} data-toggle="tooltip" data-placement="bottom" title={isPlaying ? "Pause" : "Play"}>
              {isPlaying ? <IconPlayerPauseFilled /> : <IconPlayerPlayFilled />}
            </button>
          </div>
          
          <div className="flex-fill col d-flex justify-content-start pl-0">
            <button className="btn btn-sm btn-dark mr-1" onClick={seekForward} data-toggle="tooltip" data-placement="bottom" title="Seek Forward 2 sec">
              <IconArrowForwardUp />
            </button>
            <button className={`btn btn-sm btn-dark mr-1 ${playbackRate === 0.5 ? 'bg-primary border-primary' : ''}`} onClick={() => changePlaybackRate(0.5)} data-toggle="tooltip" data-placement="bottom" title="0.5x">
              0.5x
            </button>
            <button className={`btn btn-sm btn-dark mr-1 ${playbackRate === 1 ? 'bg-primary border-primary' : ''}`} onClick={() => changePlaybackRate(1)} data-toggle="tooltip" data-placement="bottom" title="1x">
              1x
            </button>
            <button className={`btn btn-sm btn-dark mr-1 ${playbackRate === 2 ? 'bg-primary border-primary' : ''}`} onClick={() => changePlaybackRate(2)} data-toggle="tooltip" data-placement="bottom" title="2x">
              2x
            </button>
            <button className={`btn btn-sm btn-dark mr-1 ${playbackRate === 3 ? 'bg-primary border-primary' : ''}`} onClick={() => changePlaybackRate(3)} data-toggle="tooltip" data-placement="bottom" title="3x">
              3x
            </button>
            <button className={`btn btn-sm btn-dark ${playbackRate === 3.5 ? 'bg-primary border-primary' : ''}`} onClick={() => changePlaybackRate(3.5)} data-toggle="tooltip" data-placement="bottom" title="3.5x">
              3.5x
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}

const wrapperStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};
