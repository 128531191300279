import React, { useRef, useState } from 'react';
import { useGetPlayerImageQuery, useUploadPlayerImageMutation } from '../api/playerSlice';

const PlayerImage = ({ playerId }) => {
    const fileInputRef = useRef(null);
    const [error, setError] = useState('');
    const { data: imageUrl, refetch: refetchImage } = useGetPlayerImageQuery(playerId);
    const [uploadImage] = useUploadPlayerImageMutation();

    const validateFile = (file) => {
        // Check file type
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!validTypes.includes(file.type)) {
            return 'Only JPG and PNG files are allowed';
        }

        // Check file size (5MB)
        if (file.size > 5 * 1024 * 1024) {
            return 'File size must be less than 5MB';
        }

        return null;
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setError('');
            const validationError = validateFile(file);
            
            if (validationError) {
                setError(validationError);
                return;
            }

            try {
                await uploadImage({ playerId, file }).unwrap();
                refetchImage();
            } catch (error) {
                setError(error.data || 'Failed to upload image');
            }
        }
    };

    toastr.options.positionClass = "toast-bottom-right";
    return (
        <div className="mb-4 text-center">
            {error && (
                toastr.error(error)
            )}
            <div className="relative inline-block">
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="Player"
                        style={{ maxHeight: '400px' }} 
                        className="w-48 h-48 rounded-full object-cover border-2 border-gray-200"
                    />
                ) : (
                    <div className="w-48 h-48 rounded-full bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500">No Image</span>
                    </div>
                )}
            </div>
            {/* <button
                onClick={() => fileInputRef.current?.click()}
                className="mt-2 p-2"
                title="Upload new image"
            >
                Upload
            </button> */}
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/jpeg,image/jpg,image/png"
                className="hidden"
            />
        </div>
    );
};

export default PlayerImage;