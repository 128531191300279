const CLIENT_ID = process.env.CLIENT_ID || '0oag5lrjafYfLBJOf4x7';
const ISSUER = process.env.ISSUER || 'https://auth-internal.goldensetanalytics.com/oauth2/ausky67gkehdqiaj24x7';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = window.location.origin;
const REDIRECT_URI = `${BASENAME}/login/callback`;


// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'roles'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    tokenManager: {
      storage: 'localStorage'  // Explicitly set storage type
    }
  }
};