import React from "react";
import { speedUnitOptions, numberOfSetsOptions, tieBreakFinalSetOptions, typeOfScoringOptions, matchFormatsOptions, playerHandednessOptions } from "./Utils/options";

const MatchDetails = ({ match }) => {
  const parseValueToOption = (value, options) => {
    const option = options.find(option => option.value === value);
    return option ? option.label : 'Nothing selected';
  }

  return (
    <div className="mb-1">
      <div
        className="d-flex align-items-center btn text-decoration-none text-left"
        data-toggle="collapse"
        data-target="#collapse-match-details"
        aria-expanded="false"
        aria-controls="collapse-match-details"
      >
        <span
          className="fas fa-caret-right accordion-icon mr-3"
          data-fa-transform="shrink-2"
        ></span>
        <span className="font-weight-medium text-sans-serif text-900 mr-2">
          Match Details
        </span>
      </div>
      <div
        className="collapse card"
        id="collapse-match-details"
        aria-labelledby="collapse-match-details"
      >
        <div className="match-details-content p-3 card-body">
          <div className='row'>
            <div className='col-6'>
              <strong>Speed Unit:</strong> {parseValueToOption(match?.speedUnit, speedUnitOptions)}
            </div>
            <div className='col-6'>
              <strong>Number of Sets:</strong> {parseValueToOption(match?.numberOfSets, numberOfSetsOptions)}
            </div>
            <div className='col-6'>
              <strong>Tie Break Final Set:</strong> {parseValueToOption(match?.tieBreakFinalSet, tieBreakFinalSetOptions)}
            </div>
            <div className='col-6'>
              <strong>Type Of Scoring:</strong> {parseValueToOption(match?.typeOfScoring, typeOfScoringOptions)}
            </div>
            <div className='col-6'>
              <strong>Player 1 Handedness:</strong> {parseValueToOption(match?.player1Handedness, playerHandednessOptions)}
            </div>
            <div className='col-6'>
              <strong>Player 2 Handedness:</strong> {parseValueToOption(match?.player2Handedness, playerHandednessOptions)}
            </div>
            <div className='col-6'>
              <strong>Match Format:</strong> {parseValueToOption(match?.matchFormat, matchFormatsOptions)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchDetails;