const VideoListItem = ({ video }) => {
    return (
        <a
            style={{ cursor: "pointer" }}
            className="list-group-item list-group-item-action"
            href={`${window.location.origin}/videoslinking/${video.source}?videoProcessId=${video.id}`} target="_blank" rel="noreferrer">
            <strong>{video.title} - {video.source.toUpperCase()}</strong>
            {video.title.includes("Completed") && (
                <span className="badge badge-success ml-2">CV</span>
            )}
            {video.title.includes("New") && (
                <span className="badge badge-danger ml-2">CV</span>
            )}
            {video.title.includes("Processing") && (
                <span className="badge badge-warning ml-2">CV</span>
            )}
        </a>
    );
}

export default VideoListItem;