export const FIRST_SERVE_SHOT_ORDER_ID = 0;
export const SECOND_SERVE_SHOT_ORDER_ID = 1;
export const RETURN_SHOT_ORDER_ID = 2;
export const SERVE_PLUS_ONE_SHOT_ORDER_ID = 3;
export const SECOND_TO_LAST_SHOT_ORDER_ID = 4;
export const LAST_SHOT_ORDER_ID = 5;

export const AD_SERVICE_COURT = 0;
export const DEUCE_SERVICE_COURT = 1;

export const FIRST_SERVE = 0;
export const SECOND_SERVE = 1;
export const DOUBLE_FAULT = 2;

export const LINE_SHOT_LOCATION = 0;
export const MIDDLE_SHOT_LOCATION = 1;
export const CROSS_SHOT_LOCATION = 2;

export const WIDE_SERVICE_LOCATION = 1;
export const BODY_SERVICE_LOCATION = 2;
export const T_SERVICE_LOCATION = 3;

export const IN_SHOT_PLACEMENT = 0;
export const OUT_SHOT_PLACEMENT = 1;
export const NET_SHOT_PLACEMENT = 2;

export const ONE_SET = 1;
export const THREE_SETS = 3;
export const FIVE_SETS = 5;

export const MPH_SPEED_UNIT = 0;
export const KMH_SPEED_UNIT = 1;

export const AD_SCORING = 1;
export const NO_AD_SCORING = 2;

export const SINGLE_SET = "SingleSet";
export const BEST_OF_THREE_TIEBREAK_SETS = "BestOfThreeTiebreakSets";
export const BEST_OF_FIVE_TIEBREAK_SETS = "BestOfFiveTiebreakSets";
export const BEST_OF_THREE_TEN_POINT_FINAL_SET_TIEBREAK = "BestOfThreeTenPointFinalSetTiebreak";
export const BEST_OF_FIVE_TEN_POINT_FINAL_SET_TIEBREAK = "BestOfFiveTenPointFinalSetTiebreak";
export const BEST_OF_THREE_FAST_FOUR_SETS = "BestOfThreeFastFourSets";
export const EIGHT_GAME_PRO_SET = "EightGameProSet";

export const SEVEN_TIE_BREAK_FINAL_SET = 7;
export const TEN_TIE_BREAK_FINAL_SET = 10;

export const SLOW_SHOT_SPEED = 55;
export const AVERAGE_SHOT_SPEED = 65;
export const FAST_SHOT_SPEED = 75;

export const NORMAL_STROKE_TYPE = 0;
export const VOLLEY_STROKE_TYPE = 1;
export const LOB_STROKE_TYPE = 2;
export const PASSING_STROKE_TYPE = 3;
export const OVERHEAD_STROKE_TYPE = 4;
export const SLICE_STROKE_TYPE = 5;
export const DROPSHOT_STROKE_TYPE = 6;
export const ACE_STROKE_TYPE = 7;
export const DOUBLE_FAULT_STROKE_TYPE = 8;

export const FOREHAND_SHOT_TYPE = 0;
export const BACKHAND_SHOT_TYPE = 1;
export const SERVE_SHOT_TYPE = 2;

export const WINNER_RALLY_ENDING_SHOT_TYPE = 0;
export const FORCING_RALLY_ENDING_SHOT_TYPE = 1;
export const UNFORCED_RALLY_ENDING_SHOT_TYPE = 2;
export const FORCED_RALLY_ENDING_SHOT_TYPE = 3;
export const ERROR_RALLY_ENDING_SHOT_TYPE = 4;

export const BEHIND_BASE_VERTICAL_PLAYER_POSITION = 0;
export const BACK_COURT_VERTICAL_PLAYER_POSITION = 1;
export const FORE_COURT_VERTICAL_PLAYER_POSITION = 2;

export const DEUCE_HORIZONTAL_PLAYER_POSITION = 0;
export const MIDDLE_HORIZONTAL_PLAYER_POSITION = 1;
export const AD_HORIZONTAL_PLAYER_POSITION = 2;

export const STATIONARY_SHOT_MOVEMENT_TYPE = 0;
export const RUNNING_SHOT_MOVEMENT_TYPE = 1;
export const NONE_SHOT_MOVEMENT_TYPE = 2;

export const EDITOR_TRACKING_EVENTS = {
    PLAY: "Play",
    PAUSE: "Pause",
    SEEK_FORWARD: "SeekForward",
    SEEK_BACKWARD: "SeekBackward",
    RESET_POINT: "ResetPoint",
    CHANGED_PLAYBACK_RATE: "ChangedPlaybackRate",
}

export const PLAYER_LEFT_HANDED = 0;
export const PLAYER_RIGHT_HANDED = 1;

export const AD_CORNER_SHOT_PLACEMENT = 0;
export const MIDDLE_SHOT_PLACEMENT = 1;
export const DEUCE_CORNER_SHOT_PLACEMENT = 2;
