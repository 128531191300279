import { useSelector, useDispatch } from "react-redux";
import { selectActivePoint, editActivePoint, selectMatch, selectCurrentPlayerTime } from "../store/editorSlice";
import ButtonGroup from "../../../components/ButtonGroup";
import { shotLocationTypeOptions } from "../Utils/options";
import { shotPlacementTypeOptions } from "../Utils/options";
import * as constants from "../Utils/constants";
import { trackEditPointEvent } from "../Utils/trackEditPointUtils";
import { useOktaAuth } from "@okta/okta-react";

const EditReturn = ({ shot, videoProcessId }) => {
    const dispatch = useDispatch();
    const activePoint = useSelector(selectActivePoint);
    const match = useSelector(selectMatch);
    const { authState, oktaAuth } = useOktaAuth();
    const currentPlayerTime = useSelector(selectCurrentPlayerTime);

    let shotPlacementValue = (!shot.out && !shot.net) ? constants.IN_SHOT_PLACEMENT : shot.out ? constants.OUT_SHOT_PLACEMENT : constants.NET_SHOT_PLACEMENT;

    const optionChangedHandler = (field, value) => {
        trackEditPointEvent(field, shot[field], value, videoProcessId, activePoint, currentPlayerTime, authState);
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const shotPlacementChangedHandler = (field, value) => {
        trackEditPointEvent(field, shotPlacementValue, value, videoProcessId, activePoint, currentPlayerTime, authState);
        const updatedPoint = { ...activePoint };
        const updatedShot = { ...shot };
        switch (value) {
            case constants.IN_SHOT_PLACEMENT:
                updatedShot.out = false;
                updatedShot.net = false;
                break;
            case constants.OUT_SHOT_PLACEMENT:
                updatedShot.out = true;
                updatedShot.net = false;
                break;
            case constants.NET_SHOT_PLACEMENT:
                updatedShot.out = false;
                updatedShot.net = true;
                break;
            default:
                break;

        }
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    return (
        <>
            <span>Return</span>
            <div className="mb-1">
                <ButtonGroup field="returnPlacement" value={shotPlacementValue} options={shotPlacementTypeOptions} onOptionChanged={shotPlacementChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotLocationId" value={shot.shotLocationId} options={shotLocationTypeOptions} onOptionChanged={optionChangedHandler} />
            </div>
        </>
    );
}

export default EditReturn