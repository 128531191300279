import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const collectedVideosSlice = createApi({
  reducerPath: "videosapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/videos`,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["collectedvideos", "readyVideosForLinking"],
  endpoints: (builder) => ({
    getAllCollectedVideos: builder.query({
      query: ({ category, status }) => `/all?category=${category}&status=${status}`,
      providesTags: (result, error, category, status) =>
        [
          "collectedvideos",
          { type: "collectedvideos", category: category, status: status },
        ],
      extraOptions: { maxRetries: 3 },
      transformResponse: (response) => {
        return response;
      },
    }),

    getReadyVideoForLinkingById: builder.query({
      query: (videoProcessId) => `/ready/${videoProcessId}`,
      extraOptions: { maxRetries: 3 },
      transformResponse: (video) => {
        //format date to be more readable
        video.metadata.match_date = new Date(video.metadata.match_date).toLocaleDateString();
        video.title = `${video.metadata.p1} vs ${video.metadata.p2} - ${video.metadata.tournament} - ${video.metadata.match_date}`;
        if (video.source_url.includes("dropbox")) {
          let parts = video.metadata.title.split("--");
          if (parts.length < 3) {
            video.title = video.metadata.title;
            return;
          }
          let year = parts[2];
          //confirm year is 4 digits
          if (year.length === 4) {
            video.title = `${video.metadata.p1} vs ${video.metadata.p2} - ${video.metadata.tournament} - ${year}`;
          } else {
            video.title = video.metadata.title;
          }
        }

        if (video.source_url.includes("ddsa")) {
          video.title = video.metadata.title.replace('.mp4', '');
        }

        //c.recent_cv_status != null and (c.recent_cv_status.status = 'SENT' OR c.recent_cv_status.status = 'DOWNLOADING' OR c.recent_cv_status.status = 'PROCESSING')
        if (video.recent_cv_status !== null && (video.recent_cv_status.status === 'SENT' || video.recent_cv_status.status === 'DOWNLOADING' || video.recent_cv_status.status === 'PROCESSING')) {
          video.isCvProcessing = true;
          video.title = `${video.title} - CV Processing`;
        }
        else if (video.recent_cv_status !== null && video.recent_cv_status.status === 'FINISHED') {
          video.isCvCompleted = true;
          video.title = `${video.title} - CV Completed`;
        } else {
          video.isCvNew = true;
          video.title = `${video.title} - New`;
        }

        if (video.linked_metadata === null) {
          video.linked_metadata = {
            player1Id: undefined,
            player2Id: undefined,
            eventInstanceId: undefined,
            winnerId: undefined,
            result: '',
            resultType: '',
            roundId: undefined,
            matchType: ''
          };
        }

        // confirm that linked_metadata has all the required fields and if not add them
        if (video.linked_metadata.player1Id === undefined) {
          video.linked_metadata.player1Id = null;
        }
        if (video.linked_metadata.player2Id === undefined) {
          video.linked_metadata.player2Id = null;
        }
        if (video.linked_metadata.eventInstanceId === undefined) {
          video.linked_metadata.eventInstanceId = null;
        }
        if (video.linked_metadata.winnerId === undefined) {
          video.linked_metadata.winnerId = null;
        }
        if (video.linked_metadata.result === undefined) {
          video.linked_metadata.result = '';
        }
        if (video.linked_metadata.resultType === undefined) {
          video.linked_metadata.resultType = '';
        }
        if (video.linked_metadata.roundId === undefined) {
          video.linked_metadata.roundId = null;
        }
        if (video.linked_metadata.matchType === undefined) {
          video.linked_metadata.matchType = '';
        }
        return video;
      },
      providesTags: (result, error, args) => ["readyVideosForLinking", { type: "readyVideosForLinking", id: args }],
    }),
    getEditorVideo: builder.query({
      query: (videoId) => `/editor/${videoId}`,
    }),
    getSearchVideos: builder.query({
      query: (searchText) => `/search?searchText=${searchText}`
    }),

    getAllReadyVideosForLinking: builder.query({
      query: ({ category, isLinked }) => `/ready?category=${category}&isLinked=${isLinked === undefined ? '' : isLinked}`,
      extraOptions: { maxRetries: 3 },
      transformResponse: (response) => {
        response.forEach((video) => {
          video.isFiltered = true;
          //format date to be more readable
          video.metadata.match_date = new Date(video.metadata.match_date).toLocaleDateString();
          video.title = `${video.metadata.p1} vs ${video.metadata.p2} - ${video.metadata.tournament} - ${video.metadata.match_date}`;
          if (video.source_url.includes("dropbox")) {
            let parts = video.metadata.title.split("--");
            if (parts.length < 3) {
              video.title = video.metadata.title;
              return;
            }
            let year = parts[2];
            //confirm year is 4 digits
            if (year.length === 4) {
              video.title = `${video.metadata.p1} vs ${video.metadata.p2} - ${video.metadata.tournament} - ${year}`;
            } else {
              video.title = video.metadata.title;
            }
          }

          if (video.source_url.includes("ddsa")) {
            video.title = video.metadata.title.replace('.mp4', '');
          }

          //c.recent_cv_status != null and (c.recent_cv_status.status = 'SENT' OR c.recent_cv_status.status = 'DOWNLOADING' OR c.recent_cv_status.status = 'PROCESSING')
          if (video.recent_cv_status !== null && (video.recent_cv_status.status === 'SENT' || video.recent_cv_status.status.includes('DOWNLOADING') || video.recent_cv_status.status === 'PROCESSING')) {
            video.isCvProcessing = true;
            video.title = `${video.title} - CV Processing`;
          }
          else if (video.recent_cv_status !== null && video.recent_cv_status.status === 'FINISHED') {
            video.isCvCompleted = true;
            video.title = `${video.title} - CV Completed`;
          } else {
            video.isCvNew = true;
            video.title = `${video.title} - New`;
          }
        });
        return response;
      },
      providesTags: (result, error, args) => ["readyVideosForLinking"],
    }),
    getAllReadyVideosForLinkingV2: builder.query({
      query: ({ category, isLinked, searchP1, searchP2, searchTournament, pageNumber, pageSize }) => `?category=${category}&isLinked=${isLinked === undefined ? '' : isLinked}&searchP1=${searchP1}&searchP2=${searchP2}&searchTournament=${searchTournament}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      extraOptions: { maxRetries: 3 },
      transformResponse: (response) => {
        response.videos.forEach((video) => {
          video.isFiltered = true;
          //format date to be more readable
          video.metadata.match_date = new Date(video.metadata.match_date).toLocaleDateString();
          video.title = `${video.metadata.p1} vs ${video.metadata.p2} - ${video.metadata.tournament} - ${video.metadata.match_date}`;
          if (video.source_url.includes("dropbox")) {
            let parts = video.metadata.title.split("--");
            if (parts.length < 3) {
              video.title = video.metadata.title;
              return;
            }
            let year = parts[2];
            //confirm year is 4 digits
            if (year.length === 4) {
              video.title = `${video.metadata.p1} vs ${video.metadata.p2} - ${video.metadata.tournament} - ${year}`;
            } else {
              video.title = video.metadata.title;
            }
          }

          if (video.source_url.includes("ddsa")) {
            video.title = video.metadata.title.replace('.mp4', '');
          }

          //c.recent_cv_status != null and (c.recent_cv_status.status = 'SENT' OR c.recent_cv_status.status = 'DOWNLOADING' OR c.recent_cv_status.status = 'PROCESSING')
          if (video.recent_cv_status !== null && (video.recent_cv_status.status === 'SENT' || video.recent_cv_status.status.includes('DOWNLOADING') || video.recent_cv_status.status === 'PROCESSING')) {
            video.isCvProcessing = true;
            video.title = `${video.title} - CV Processing`;
          }
          else if (video.recent_cv_status !== null && video.recent_cv_status.status === 'FINISHED') {
            video.isCvCompleted = true;
            video.title = `${video.title} - CV Completed`;
          } else {
            video.isCvNew = true;
            video.title = `${video.title} - New`;
          }
        });
        return response;
      },
      providesTags: (result, error, args) => ["readyVideosForLinking"],
    }),
    downloadVideo: builder.mutation({
      query: ({ match, category }) => ({
        url: `/request/download?category=${category}`,
        method: "POST",
        body: match,
      }),
      invalidatesTags: (result, error, args) => [
        { type: "collectedvideos", category: args.category, status: "download" },
        {
          type: "collectedvideos",
          category: args.category,
          status: "downloadprocessing",
        },
      ],
    }),
    sendVideo: builder.mutation({
      query: ({ videoId, env, screenCoordinates }) => ({
        url: `/cv/send?id=${videoId}&env=${env}&screenCoordinates=${screenCoordinates}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, args) => [
        { type: "collectedvideos", category: args.category, status: "new" },
        { type: "collectedvideos", category: args.category, status: "cvprocessing" },
        "readyVideosForLinking"
      ],
    }),
    resetCvVideoStatus: builder.mutation({
      query: ({ videoId }) => ({
        url: `/cv/reset?id=${videoId}`,
        method: "POST",
      }),

      invalidatesTags: (result, error, args) => [
        { type: "collectedvideos", category: args.category, status: "new" },
        { type: "collectedvideos", category: args.category, status: "cvprocessing" },
        "readyVideosForLinking"
      ],
    }),
    rerunCvVideo: builder.mutation({
      query: ({ videoId }) => ({
        url: `/cv/rerun?id=${videoId}`,
        method: "POST",
      }),

      invalidatesTags: (result, error, args) => [
        { type: "collectedvideos", category: args.category, status: "new" },
        { type: "collectedvideos", category: args.category, status: "cvprocessing" },
        "readyVideosForLinking"
      ],
    }),
    moveCvToVideoApp: builder.mutation({
      query: ({ videoId, snapshotId, targetFormat }) => ({
        url: `/cv/move?videoId=${videoId}&snapshotId=${snapshotId}&targetFormat=${targetFormat}`,
        method: "POST",
      })
    }),
    moveToFull: builder.mutation({
      query: (request) => ({
        url: `/move/to/full?videoId=${request.videoId}`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (result, error, args) => ["readyVideosForLinking"],
    }),
    updateMetadata: builder.mutation({
      query: (linkedMetadata) => ({
        url: `/update/${linkedMetadata.videoId}?`,
        method: "POST",
        body: linkedMetadata,
      })
    }),
    updateTracker: builder.mutation({
      query: ({ videoProcessId, editorTracker }) => ({
        url: `/update/${videoProcessId}/editor`,
        method: "POST",
        body: editorTracker,
      })
    }),
    unlinkVideo: builder.mutation({
      query: (videoProcessId) => ({
        url: `/unlink/${videoProcessId}`,
        method: "PUT",
      })
    }),
    updateEncodingJobId: builder.mutation({
      query: ({ id, jobId }) => ({
        url: `/encode-job-id/update/${id}?jobId=${jobId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, args) => ["readyVideosForLinking"],
    }),
    updateExtendedMatchDetails: builder.mutation({
      query: ({ videoId, extendedMatchDetails }) => ({
        url: `/update/${videoId}/extended-match-details`,
        method: "POST",
        body: extendedMatchDetails,
      })
    }),
    updateTrimMatchVideoJobId: builder.mutation({
      query: ({ id, jobId }) => ({
        url: `/trimmatchvideo-job-id/update/${id}?jobId=${jobId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, args) => ["readyVideosForLinking"],
    }),
    enqueueVideo: builder.mutation({
      query: ({ id, category }) => ({
        url: `/re-download?category=${category}&id=${id}`,
        method: "POST",
      })
    }),
    refreshManualCollectedVideos: builder.mutation({
      query: () => ({
        url: `/refresh-manual-collected`,
        method: "POST",
      })
    }),
    getMoveRunStatus: builder.query({
      query: (runId) => `/move/status?runId=${runId}`,
    }),
  }),
});

export const {
  useGetAllCollectedVideosQuery,
  useGetAllReadyVideosForLinkingQuery,
  useLazyGetAllReadyVideosForLinkingV2Query,
  useLazyGetEditorVideoQuery,
  useLazyGetSearchVideosQuery,
  useLazyGetReadyVideoForLinkingByIdQuery,
  useDownloadVideoMutation,
  useSendVideoMutation,
  useResetCvVideoStatusMutation,
  useRerunCvVideoMutation,
  useMoveToFullMutation,
  useUpdateMetadataMutation,
  useUpdateTrackerMutation,
  useUnlinkVideoMutation,
  useGetMoveRunStatusQuery,
  useMoveCvToVideoAppMutation,
  useUpdateEncodingJobIdMutation,
  useUpdateTrimMatchVideoJobIdMutation,
  useEnqueueVideoMutation,
  useRefreshManualCollectedVideosMutation,
  useUpdateExtendedMatchDetailsMutation,
} = collectedVideosSlice;
