import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { secondsToTime } from "../../../utils/app-utils";
import {
  removePoint,
  selectEditMode,
  setActivePoint,
  addPoint,
  selectActivePoint,
  selectMatch,
  selectIsPlaying,
  setIsPlaying,
} from "../store/editorSlice";
import classes from "../../editHE/styles.module.css";
import DeleteButton from "../../../UI/Buttons/DeleteButton";
import { useOktaAuth } from "@okta/okta-react";
import { getPointProgress, getPointErrors } from "../Utils/pointUtils";
import * as constants from "../Utils/constants";
import AppTrackingEvents from "../../../applicationInsights/eventConstants";
import { startTrackPage,  stopTrackPage } from "../../../applicationInsights/appInsights";
import { usePageVisibility } from "../../../applicationInsights/utils";

export default function PointRowItem({ point, currentIndex, videoProcessId }) {
  const dispatch = useDispatch();

  const getPageProperties = () => {
    return {
      pointId: activePoint.id,
      pointScore: activePoint.score,
      timestamp: new Date().toISOString(),
      user: authState.idToken.claims.preferred_username,
      videoProcessId: videoProcessId,
      eventName: AppTrackingEvents.EDITING_POINT_VIEW,
    };
  };

  usePageVisibility(getPageProperties);

  const [showBtns, setShowBtns] = useState(false);

  const editMode = useSelector(selectEditMode);
  const activePoint = useSelector(selectActivePoint);
  const match = useSelector(selectMatch);
  const isPlaying = useSelector(selectIsPlaying);
  const { authState, oktaAuth } = useOktaAuth();

  const progress = getPointProgress(point, match);
  const errors = getPointErrors(point);

  let actionButton = null;
  if (isPlaying && activePoint?.id === point.id) {
    actionButton = (
      <div
        key={`pause-${point.id}`}
        style={{ cursor: "pointer", marginRight: "1rem" }}
        onClick={(e) => {
          dispatch(setIsPlaying(false));
        }}
      >
        <span className="fas fa-pause"></span>
      </div>
    );
  } else {
    actionButton = (
      <div
        key={`play-${point.id}`}
        style={{ cursor: "pointer", marginRight: "1rem" }}
        onClick={(e) => handlePointClick(point)}
      >
        <span className="fas fa-play"></span>
      </div>
    );
  }

  let pointserve =
    point.serveTypeId === constants.FIRST_SERVE
      ? "1st"
      : point.serveTypeId === constants.SECOND_SERVE
        ? "2nd"
        : "Double Fault";

  let pointserveColor =
    point.serveTypeId === constants.FIRST_SERVE
      ? "badge-soft-info"
      : point.serveTypeId === constants.SECOND_SERVE
        ? "badge-soft-primary"
        : "badge-soft-danger";

  let court = point.serviceCourt === constants.AD_SERVICE_COURT ? "Ad" : "Deuce";
  let courtColor = point.serviceCourt === constants.AD_SERVICE_COURT ? "badge-soft-primary" : "badge-soft-info";

  const handlePointClick = (point) => {
    if (editMode) {
      stopTrackPage(AppTrackingEvents.EDITING_POINT_VIEW, window.location.href, {
        pointId: activePoint.id,
        pointScore: activePoint.score,
        timestamp: new Date().toISOString(),
        user: authState.idToken.claims.preferred_username,
        videoProcessId: videoProcessId,
      });
      setTimeout(() => startTrackPage(AppTrackingEvents.EDITING_POINT_VIEW));
    }
    dispatch(setActivePoint(point));
  };


  return (
    <div
      className="list-group-item"
      style={{ backgroundColor: activePoint?.id === point.id ? "#5562AA" : errors.length > 0 ? "#50243C" : "" }}
      onMouseEnter={() => setShowBtns(true)}
      onMouseLeave={() => setShowBtns(false)}
    >
      <div className="row justify-content-between">
        <div style={{ width: "90%" }} className="d-flex flex-column align-items-start justify-content-center">
          <div style={{ width: "100%" }} className={"row align-items-center justify-content-between ml-0"}>
            <label className={`${classes["match-list-item-label-text"]}`} >
              <span className={`${classes['match-list-item-label-text']} ${point.needsReview ? "text-danger" : point.isUpdated ? "text-success" : ""}`}

                onClick={(e) => handlePointClick(point)}>
                {secondsToTime(point.startTime) + " - " + point.score}
              </span>
            </label>
            {actionButton}
          </div>

          {point.isManuallyAdded && (
            <div className="row mt-2 ml-0">
              <span className="badge badge-soft-danger rounded-capsule match-capsule-indicator ml-1">
                Is Manually Added
              </span>
            </div>
          )}
          {point.hasPlayerError && (
            <div className="row mt-2 ml-0">
              <span className="badge badge-soft-danger rounded-capsule match-capsule-indicator ml-1">
                Player Error
              </span>
            </div>
          )}

          <div className="row mt-2 ml-0">
          <span className={
                "badge rounded-capsule match-capsule-indicator ml-1 mb-1 " +
                courtColor
              }>
              {point.server}
            </span>
            {point.needsReview && (
              <span className="badge badge-danger rounded-capsule match-capsule-indicator ml-1 mb-1">
                needs review
              </span>
            )}
            <span
              className={
                "badge rounded-capsule match-capsule-indicator ml-1 mb-1 " +
                courtColor
              }
            >
              {court}
            </span>
            <span
              className={
                "badge rounded-capsule match-capsule-indicator ml-1 mb-1 " +
                pointserveColor
              }
            >
              {pointserve}
            </span>
            {point.isAce && (
              <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1 mb-1">
                ace
              </span>
            )}
            {point.isBreakPoint && (
              <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1 mb-1">
                break
              </span>
            )}
            {point.isPressurePoint && (
              <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1 mb-1">
                pressure
              </span>
            )}
            <span className="badge badge-soft-light rounded-capsule match-capsule-indicator ml-1 mb-1">
              Length: {point.rallyLength}
            </span>
            <span className="badge badge-soft-light rounded-capsule match-capsule-indicator ml-1 mb-1">
              Duration: {point.endTime - point.startTime} sec
            </span>
          </div>

          {editMode && <div style={{ height: "0.2rem", fontSize: "0.5rem" }} className="progress w-100 bg-light font-weight-black">
            <div style={{ width: `${progress}%`, backgroundColor: progress == 100 ? "#005D00" : errors.length > 0 ? "#FF0404" : "#47B947" }} className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          </div>}
        </div>
        {editMode && showBtns && (
          <div style={{ width: "10%" }} className="d-flex flex-column justify-content-between align-items-center m-0 p-0">
            <div
              className={classes["add-point"]}
              onClick={() => {
                dispatch(addPoint({ index: currentIndex, addedBy: authState.idToken.claims.preferred_username }));
              }}
            >
              <i className="fas fa-plus-circle"></i>
            </div>
            <DeleteButton
              extraClasses={`btn-sm ${classes["delete-point"]}`}
              onClick={() => dispatch(removePoint(point.id))} />

            <div
              className={classes["add-point"]}
              onClick={() => {
                dispatch(addPoint({ index: currentIndex + 1, addedBy: authState.idToken.claims.preferred_username }));
              }}
            >
              <i className="fas fa-plus-circle"></i>
            </div>
          </div>
        )}
      </div>
    </div >
  );
}
