import * as constants from './constants';

export const serviceCourtOptions = [
    { value: constants.AD_SERVICE_COURT, label: "Ad" },
    { value: constants.DEUCE_SERVICE_COURT, label: "Deuce" },
];

export const serveTypeOptions = [
    { value: constants.FIRST_SERVE, label: "1st" },
    { value: constants.SECOND_SERVE, label: "2nd" },
    { value: constants.DOUBLE_FAULT, label: "Double Fault" },
];

export const shotLocationTypeOptions = [
    { value: constants.LINE_SHOT_LOCATION, label: "Line" },
    { value: constants.MIDDLE_SHOT_LOCATION, label: "Middle" },
    { value: constants.CROSS_SHOT_LOCATION, label: "Cross" },
];

export const serviceLocationTypeOptions = [
    { value: constants.WIDE_SERVICE_LOCATION, label: "Wide" },
    { value: constants.BODY_SERVICE_LOCATION, label: "Body" },
    { value: constants.T_SERVICE_LOCATION, label: "T" }
];

export const shotPlacementTypeOptions = [
    { value: constants.IN_SHOT_PLACEMENT, label: "In" },
    { value: constants.OUT_SHOT_PLACEMENT, label: "Out" },
    { value: constants.NET_SHOT_PLACEMENT, label: "Net" }
];

export const numberOfSetsOptions = [
    { value: constants.ONE_SET, label: "1" },
    { value: constants.THREE_SETS, label: "3" },
    { value: constants.FIVE_SETS, label: "5" },
];

export const speedUnitOptions = [
    { value: constants.MPH_SPEED_UNIT, label: "MPH" },
    { value: constants.KMH_SPEED_UNIT, label: "KMH" }
];

export const typeOfScoringOptions = [
    { value: constants.AD_SCORING, label: "Ad" },
    { value: constants.NO_AD_SCORING, label: "No-Ad" }
];

export const matchFormatsOptions = [
    { value: constants.SINGLE_SET, label: "Single Set" },
    { value: constants.BEST_OF_THREE_TIEBREAK_SETS, label: "Best Of Three Tiebreak Sets" },
    { value: constants.BEST_OF_FIVE_TIEBREAK_SETS, label: "Best Of Five Tiebreak Sets" },
    { value: constants.BEST_OF_THREE_TEN_POINT_FINAL_SET_TIEBREAK, label: "Best Of Three Ten Point Final Set Tiebreak" },
    { value: constants.BEST_OF_FIVE_TEN_POINT_FINAL_SET_TIEBREAK, label: "Best Of Five Ten Point Final Set Tiebreak" },
    { value: constants.BEST_OF_THREE_FAST_FOUR_SETS, label: "Best Of Three Fast Four Sets" },
    { value: constants.EIGHT_GAME_PRO_SET, label: "Eight Game Pro Set" }
]

export const tieBreakFinalSetOptions = [
    { value: constants.SEVEN_TIE_BREAK_FINAL_SET, label: "7" },
    { value: constants.TEN_TIE_BREAK_FINAL_SET, label: "10" }
];

export const shotSpeedOptions = [
    { value: constants.SLOW_SHOT_SPEED, label: "Slow" },
    { value: constants.AVERAGE_SHOT_SPEED, label: "Average" },
    { value: constants.FAST_SHOT_SPEED, label: "Fast" }
];

export const strokeTypeOptions = [
    { value: constants.NORMAL_STROKE_TYPE, label: "Normal" },
    { value: constants.VOLLEY_STROKE_TYPE, label: "Volley" },
    { value: constants.LOB_STROKE_TYPE, label: "Lob" },
    { value: constants.PASSING_STROKE_TYPE, label: "Passing" },
    { value: constants.OVERHEAD_STROKE_TYPE, label: "Overhead" },
    { value: constants.SLICE_STROKE_TYPE, label: "Slice" },
    { value: constants.DROPSHOT_STROKE_TYPE, label: "Dropshot" },
];

export const shotTypeOptions = [
    { value: constants.FOREHAND_SHOT_TYPE, label: "FH" },
    { value: constants.BACKHAND_SHOT_TYPE, label: "BH" },
];

export const rallyEndingShotTypeOptionsImg = [
    { value: constants.WINNER_RALLY_ENDING_SHOT_TYPE, label: "Winner" },
    { value: constants.UNFORCED_RALLY_ENDING_SHOT_TYPE, label: "Error" }
];

export const rallyEndingShotTypeOptions = [
    { value: constants.WINNER_RALLY_ENDING_SHOT_TYPE, label: "Winner" },
    { value: constants.UNFORCED_RALLY_ENDING_SHOT_TYPE, label: "Unforced" },
    { value: constants.FORCING_RALLY_ENDING_SHOT_TYPE, label: "Forcing" }
];

export const verticalPlayerPositionOptions = [
    { value: constants.BEHIND_BASE_VERTICAL_PLAYER_POSITION, label: "Behind Base" },
    { value: constants.BACK_COURT_VERTICAL_PLAYER_POSITION, label: "Back Court" },
    { value: constants.FORE_COURT_VERTICAL_PLAYER_POSITION, label: "Fore Court" },
];

export const horizontalPlayerPositionOptions = [
    { value: constants.DEUCE_HORIZONTAL_PLAYER_POSITION, label: "Deuce" },
    { value: constants.MIDDLE_HORIZONTAL_PLAYER_POSITION, label: "Middle" },
    { value: constants.AD_HORIZONTAL_PLAYER_POSITION, label: "Ad" },
];

export const shotMovementTypeOptions = [
    { value: constants.STATIONARY_SHOT_MOVEMENT_TYPE, label: "Stationary" },
    { value: constants.RUNNING_SHOT_MOVEMENT_TYPE, label: "Running" },
];

export const playerHandednessOptions = [
    { value: constants.PLAYER_LEFT_HANDED, label: "Lefty" },
    { value: constants.PLAYER_RIGHT_HANDED, label: "Righty" }
];

export const shotPlacementOptions = [
    { value: constants.AD_CORNER_SHOT_PLACEMENT, label: "Ad Corner" },
    { value: constants.MIDDLE_SHOT_PLACEMENT, label: "Middle" },
    { value: constants.DEUCE_CORNER_SHOT_PLACEMENT, label: "Deuce Corner" },
];

export const disableServeSpeedsOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];