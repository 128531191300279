import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setUser } from "./features/auth/authSlice";
import RequiredAuth from "./routes/SecureRoute";
import PathConstants from "./routes/pathConstants";

import { useSelector } from "react-redux";
import {
  selectIsAdmin,
  selectIsCV,
  selectIsCoder,
  selectIsRecordingTeam,
} from "./features/auth/authSlice";

export default function Layout() {
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const isCoder = useSelector(selectIsCoder);
  const isRecordingTeam = useSelector(selectIsRecordingTeam);
  const isCV = useSelector(selectIsCV);
  const { authState, oktaAuth } = useOktaAuth();
  const handleLogin = () => oktaAuth.signInWithRedirect();
  const handleLogout = () => {
    oktaAuth.signOut();
    dispatch(setUser(null));
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setTimeout(() => {
        oktaAuth.getUser().then((info) => {
          dispatch(setUser(info));
        });
      }, 400);
    }
  }, [authState, oktaAuth]);

  return (
    <div>
      <main className="main" id="top">
        <div className="container-fluid" data-layout="container">
          <nav className="navbar navbar-dark navbar-glass navbar-top sticky-kit navbar-expand-lg">
            <button
              className="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarStandard"
              aria-controls="navbarStandard"
              aria-expanded="false"
              aria-label="Toggle Navigation">

              <span className="navbar-toggle-icon">
                <span className="toggle-line"></span>
              </span>

            </button>
            <a className="navbar-brand" href="/">
              <div className="d-flex align-items-center py-3">
                <img
                  className="mr-2"
                  src="assets/img/gsa-full-logo-gold.png"
                  alt=""
                  width="105"
                />
              </div>
            </a>
            <div className="collapse navbar-collapse" id="navbarStandard">
              <ul className="navbar-nav ">
                <li className="nav-item">
                  <NavLink activeclassname="active" to={PathConstants.INDEX} className="nav-link"> Home </NavLink>
                </li>
                {(isAdmin) && (
                  <li className="nav-item">
                    <NavLink activeclassname="active" to={`${PathConstants.CLIENTS}`} className="nav-link"> Clients </NavLink>
                  </li>
                )}
                {(isAdmin) && (
                  <li className="nav-item">
                    <NavLink activeclassname="active" to={`${PathConstants.PLAYERS}`} className="nav-link"> Players </NavLink>
                  </li>
                )}
                {(isCoder && !isAdmin) && (
                  <li className="nav-item">
                    <NavLink activeclassname="active" to={`${PathConstants.EDITOR_VIDEOS}`} className="nav-link"> Editor Videos </NavLink>
                  </li>
                )}
                {(isAdmin || isRecordingTeam || isCV) && (
                  <li className="nav-item dropdown dropdown-on-hover"><a className="nav-link dropdown-toggle" id="navbarDropdownComponents" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Videos</a>
                    <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownComponents">
                      <div style={{ minWidth: "20rem", maxWidth: "fit-content" }} className="card shadow-none navbar-card-components">
                        <div className="card-body">
                          <div className="row">
                            {(isAdmin || isCV) && (
                              <>
                                <NavLink className="dropdown-item nav-link py-0 font-weight-bold fs-0" activeclassname="active" to={`${PathConstants.VIDEOSSEARCH}`}>Search Videos</NavLink>
                                <div className="nav flex-column">
                                  <div className="nav-link py-1 text-900 font-weight-bold mt-1">Videos</div>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/ttv`}>Tennis TV</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/wtv`}>WTA TV</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/ctv`}>Challenger TV</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/wtt`}>ITF</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/tc`}>Tennis Channel</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/espn`}>ESPN</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/dropbox`}>Dropbox</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/recording-team`}>Recording Team</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/img`}>IMG</NavLink>
                                  <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.VIDEOSLINKING}/ddsa`}>DDSA</NavLink>
                                </div>
                              </>
                            )}
                            {(isAdmin || isRecordingTeam) && (
                              <div className="nav flex-column">
                                <div className="nav-link py-1 text-900 font-weight-bold mt-1">Linking</div>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/ttv`}>Tennis TV</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/wtv`}>WTA TV</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/ctv`}>Challenger TV</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/wtt`}>ITF</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/tc`}>Tennis Channel</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/espn`}>ESPN</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/dropbox`}>Dropbox</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/recording-team`}>Recording Team</NavLink>
                                <NavLink className="dropdown-item nav-link py-1 font-weight-medium" activeclassname="active" to={`${PathConstants.CODERSVIDEOSLINKING}/img`}>IMG</NavLink>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {(isAdmin) &&
                  <li className="nav-item dropdown dropdown-on-hover row align-items-center ml-0">
                    <a className="nav-link dropdown-toggle" id="navbarDropdownONC" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Draws</a>
                    <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownONC">
                      <div className="bg-white rounded-soft py-3">
                        <NavLink activeclassname="active" to={PathConstants.DRAWSREPORTS} className="dropdown-item">Active</NavLink>
                        <NavLink activeclassname="active" to={PathConstants.EVENTS} className="dropdown-item">Historical</NavLink>
                      </div>
                    </div>
                  </li>
                }

                {(isAdmin) && (
                  <li className="nav-item dropdown dropdown-on-hover row align-items-center ml-0">
                    <a className="nav-link dropdown-toggle" id="navbarDropdownLinking" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Reports</a>
                    <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownLinking">
                      <div className="bg-white rounded-soft py-2">
                        <NavLink activeclassname="active" to={PathConstants.CLIENTSREPORTS} className="nav-link" > Clients Reports </NavLink>
                        <NavLink activeclassname="active" to={PathConstants.USERREPORTS} className="nav-link" > User Reports </NavLink>
                      </div>
                    </div>
                  </li>
                )}

                {(isAdmin || isRecordingTeam || isCV) && (
                  <li className="nav-item dropdown dropdown-on-hover row align-items-center ml-0">
                    <a className="nav-link dropdown-toggle" id="navbarDropdownOperations" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Operations</a>
                    <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownOperations">
                      <div style={{ minWidth: "40rem", maxWidth: "fit-content" }} className="card shadow-none navbar-card-components">
                        <div className="card-body">
                          <div className="row">
                            <div className="nav flex-column">
                              <div className="nav-link py-1 text-900 font-weight-bold mt-1">General</div>
                              {(isAdmin) && (
                                <NavLink activeclassname="active" to={PathConstants.VIDEOCLIENTAPPSUMMARY} className="dropdown-item nav-link py-1 font-weight-medium">Video Client App Summary</NavLink>
                              )}
                              {(isAdmin || isCV) && (
                                <NavLink activeclassname="active" to={`${PathConstants.COLLECTEDVIDEOS}/atp/download`} className="dropdown-item nav-link py-1 font-weight-medium"> Collected Videos </NavLink>
                              )}
                              {(isAdmin || isCV) && (
                                <NavLink activeclassname="active" to={PathConstants.CVDASHBOARD} className="dropdown-item nav-link py-1 font-weight-medium" > CV Dashboard </NavLink>
                              )}
                              {(isAdmin || isCV) && (
                                <NavLink activeclassname="active" to={PathConstants.EDITHE} className="dropdown-item nav-link py-1 font-weight-medium"> Edit HE </NavLink>
                              )}
                              {(isAdmin) && (
                                <NavLink activeclassname="active" to={PathConstants.DROPBOX} className="dropdown-item nav-link py-1 font-weight-medium"> Dropbox </NavLink>
                              )}
                              {(isAdmin) && (
                                <NavLink activeclassname="active" to={PathConstants.PLAYERGROUPS} className="dropdown-item nav-link py-1 font-weight-medium" > Player Groups </NavLink>
                              )}
                              {(isAdmin) && (
                                <NavLink activeclassname="active" to={PathConstants.EDITOR_VIDEOS} className="dropdown-item nav-link py-1 font-weight-medium" > Editor Videos </NavLink>
                              )}
                              {(isAdmin) && (
                                <NavLink activeclassname="active" to={PathConstants.AO_VIDEOS} className="dropdown-item nav-link py-1 font-weight-medium" > AO Videos </NavLink>
                              )}
                            </div>
                            {(isAdmin || isRecordingTeam) && (
                              <div className="nav flex-column">
                                <div className="nav-link py-1 text-900 font-weight-bold mt-1">Lookups linking</div>
                                <NavLink activeclassname="active" to={PathConstants.PLAYERLOOKUP} className="dropdown-item nav-link py-1 font-weight-medium">Player</NavLink>
                                <NavLink activeclassname="active" to={PathConstants.TOURNAMENTLOOKUP} className="dropdown-item nav-link py-1 font-weight-medium">Tournament</NavLink>
                              </div>)}
                            {(isAdmin || isRecordingTeam) && (
                              <div className="nav flex-column">
                                <div className="nav-link py-1 text-900 font-weight-bold mt-1">ONC</div>
                                <NavLink activeclassname="active" to={PathConstants.ONCPLAYERS} className="dropdown-item nav-link py-1 font-weight-medium">Players</NavLink>
                                <NavLink activeclassname="active" to={PathConstants.ONCEVENTS} className="dropdown-item nav-link py-1 font-weight-medium">Events</NavLink>
                              </div>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}


                {(isAdmin) && (
                  <li className="nav-item dropdown dropdown-on-hover row align-items-center ml-0">
                    <a className="nav-link dropdown-toggle" id="navbarDropdownLinking" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">IMGA</a>
                    <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownLinking">
                      <div className="bg-white rounded-soft py-2">
                        <NavLink activeclassname="active" to={`${PathConstants.IMGA}/Batch_2`} className="nav-link">Batch 2</NavLink>
                        <NavLink activeclassname="active" to={`${PathConstants.IMGA}/Batch_1`} className="nav-link">Batch 1</NavLink>
                        <NavLink activeclassname="active" to={PathConstants.IMGAPLAYERS} className="nav-link" >Players</NavLink>
                      </div>
                    </div>
                  </li>
                )}

              </ul>
            </div>
            <ul className="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
              {!authState || !authState.isAuthenticated ? (
                <li className="nav-item">
                  <div className="bg-white rounded-soft py-2">
                    <a style={{ cursor: "pointer" }} onClick={handleLogin}>
                      Login
                    </a>
                  </div>
                </li>
              ) : (
                <li className="nav-item dropdown dropdown-on-hover">
                  <a
                    className="nav-link pr-0"
                    id="navbarDropdownUser"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-xl">
                      <span className="far fa-user-circle fs-4"></span>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right py-0"
                    aria-labelledby="navbarDropdownUser"
                  >
                    <div className="bg-white rounded-soft py-2">
                      <div className="dropdown-item">
                        {authState.idToken.claims.preferred_username}
                      </div>
                      <NavLink to={PathConstants.SETTINGS} className="dropdown-item">Settings</NavLink>
                      <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={handleLogout} >
                        Logout
                      </a>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </nav>
          <div className="content">
            <div className="pt-2"></div>
            <RequiredAuth />
          </div>
        </div>
      </main>
    </div>
  );
}