import { useSelector, useDispatch } from "react-redux";
import { editActivePoint, selectActivePoint, selectCurrentPlayerTime, selectMatch } from "../store/editorSlice";
import { shotTypeOptions, strokeTypeOptions, shotLocationTypeOptions, verticalPlayerPositionOptions, horizontalPlayerPositionOptions, shotMovementTypeOptions, shotPlacementOptions } from "../Utils/options";
import ButtonGroup from "../../../components/ButtonGroup";
import * as constants from "../Utils/constants";
import { trackEditPointEvent } from "../Utils/trackEditPointUtils";
import { useOktaAuth } from "@okta/okta-react";


const EditLastShots = ({ shot, videoProcessId }) => {
    const dispatch = useDispatch();

    const activePoint = useSelector(selectActivePoint);
    const { authState, oktaAuth } = useOktaAuth();
    const currentPlayerTime = useSelector(selectCurrentPlayerTime);
    const match = useSelector(selectMatch);

    const shotOptionChangedHandler = (field, value) => {
        trackEditPointEvent(`${fieldPrefix}-${field}`, shot[field], value, videoProcessId, activePoint, currentPlayerTime, authState);
        const updatedShot = { ...shot, [field]: value };

        // Calculate shotLocationId based on shotPlacementId and horizontalPlayerPositionId
        if (field === "shotPlacementId" || field === "horizontalPlayerPositionId") {
            updatedShot.shotLocationId = calculateShotLocationId(updatedShot.shotPlacementId, updatedShot.horizontalPlayerPositionId, updatedShot.shotTypeId);
        }

        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    };

    const calculateShotLocationId = (shotPlacementId, horizontalPlayerPositionId, shotTypeId) => {
        const isBounceLocationDeuce = shotPlacementId === constants.DEUCE_CORNER_SHOT_PLACEMENT;
        const playerLocation = horizontalPlayerPositionId;
        const playerHandedness = shot.isPlayerOne ? match.player1Handedness : match.player2Handedness;

        if (shotPlacementId === constants.MIDDLE_SHOT_PLACEMENT) {
            return constants.MIDDLE_SHOT_LOCATION;
        }

        switch (shotTypeId) {
            case constants.FOREHAND_SHOT_TYPE:
                if (playerLocation === constants.AD_HORIZONTAL_PLAYER_POSITION) {
                    return isBounceLocationDeuce ? constants.LINE_SHOT_LOCATION : constants.CROSS_SHOT_LOCATION;
                } else if (playerLocation === constants.DEUCE_HORIZONTAL_PLAYER_POSITION) {
                    return isBounceLocationDeuce ? constants.CROSS_SHOT_LOCATION : constants.LINE_SHOT_LOCATION;
                } else if (playerHandedness === constants.PLAYER_RIGHT_HANDED) {
                    return isBounceLocationDeuce ? constants.CROSS_SHOT_LOCATION : constants.LINE_SHOT_LOCATION;
                } else {
                    return isBounceLocationDeuce ? constants.LINE_SHOT_LOCATION : constants.CROSS_SHOT_LOCATION;
                }
            case constants.BACKHAND_SHOT_TYPE:
                if (playerLocation === constants.DEUCE_HORIZONTAL_PLAYER_POSITION) {
                    return isBounceLocationDeuce ? constants.CROSS_SHOT_LOCATION : constants.LINE_SHOT_LOCATION;
                } else if (playerLocation === constants.AD_HORIZONTAL_PLAYER_POSITION) {
                    return isBounceLocationDeuce ? constants.LINE_SHOT_LOCATION : constants.CROSS_SHOT_LOCATION;
                } else if (playerHandedness === constants.PLAYER_RIGHT_HANDED) {
                    return isBounceLocationDeuce ? constants.LINE_SHOT_LOCATION : constants.CROSS_SHOT_LOCATION;
                } else {
                    return isBounceLocationDeuce ? constants.CROSS_SHOT_LOCATION : constants.LINE_SHOT_LOCATION;
                }
            default:
                return "None";
        }
    };
    const title = shot.shotOrderId == constants.RETURN_SHOT_ORDER_ID ? "Return" : shot.shotOrderId == constants.SERVE_PLUS_ONE_SHOT_ORDER_ID ? "Serve+1" : shot.shotOrderId == constants.SECOND_TO_LAST_SHOT_ORDER_ID ? "2nd To Last" : "Last";
    const fieldPrefix = shot.shotOrderId == constants.RETURN_SHOT_ORDER_ID ? "return" : shot.shotOrderId == constants.SERVE_PLUS_ONE_SHOT_ORDER_ID ? "servePlusOne" : shot.shotOrderId == constants.SECOND_TO_LAST_SHOT_ORDER_ID ? "secondToLast" : "last";

    return (
        <>
            <span>{title}</span>
            <div className="mb-1">
                <ButtonGroup field="shotTypeId" value={shot.shotTypeId} options={shotTypeOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="strokeTypeId" value={shot.strokeTypeId} options={strokeTypeOptions.slice(0, 4)} onOptionChanged={shotOptionChangedHandler} />
                <ButtonGroup field="strokeTypeId" value={shot.strokeTypeId} options={strokeTypeOptions.slice(4, 7)} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotPlacementId" value={shot.shotPlacementId} options={shotPlacementOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotLocationId" value={shot.shotLocationId} options={shotLocationTypeOptions} onOptionChanged={shotOptionChangedHandler} disabled />
            </div>
            <div className="mb-1">
                <ButtonGroup field="verticalPlayerPositionId" value={shot.verticalPlayerPositionId} options={verticalPlayerPositionOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="horizontalPlayerPositionId" value={shot.horizontalPlayerPositionId} options={horizontalPlayerPositionOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotMovementTypeId" value={shot.shotMovementTypeId} options={shotMovementTypeOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
        </>
    )
}

export default EditLastShots;