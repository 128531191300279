import React, { useState, useEffect, useRef } from 'react';
import { useGetTimerStatusQuery, useStartTimerMutation, useStopTimerMutation, useSendHeartbeatMutation } from '../api/editorTimerSlice';
import Button from "../../UI/Buttons/Button";

const EditorTimerComponent = ({ videoProcessId }) => {
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [accumulatedSeconds, setAccumulatedSeconds] = useState(0);
  const heartbeatIntervalRef = useRef(null);
  const timerIntervalRef = useRef(null);

  const { data: timerStatus, error: timerStatusError } = useGetTimerStatusQuery({ videoProcessId });
  const [startTimer] = useStartTimerMutation();
  const [stopTimer] = useStopTimerMutation();
  const [sendHeartbeat] = useSendHeartbeatMutation();

  // Fetch timer status on component mount
  useEffect(() => {
    if (timerStatus) {
      const { isTimerRunning, totalAccumulatedSeconds } = timerStatus;
      setIsTimerRunning(isTimerRunning);
      setAccumulatedSeconds(totalAccumulatedSeconds);
    }
  }, [timerStatus]);

  // Start or stop the heartbeat and local timer based on isTimerRunning
  useEffect(() => {
    if (isTimerRunning) {
      startHeartbeat();
      startLocalTimer();
    } else {
      stopHeartbeat();
      stopLocalTimer();
    }

    // Cleanup on unmount
    return () => {
      stopHeartbeat();
      stopLocalTimer();
    };
  }, [isTimerRunning]);

  // Function to start sending heartbeats every 10 seconds
  const startHeartbeat = () => {
    heartbeatIntervalRef.current = setInterval(() => {
      sendHeartbeat({ videoProcessId });
    }, 10000); // Every 10 seconds
  };

  // Function to stop sending heartbeats
  const stopHeartbeat = () => {
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
      heartbeatIntervalRef.current = null;
    }
  };

  // Function to start a local timer to update the accumulated time display
  const startLocalTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setAccumulatedSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000); // Update every second
  };

  // Function to stop the local timer
  const stopLocalTimer = () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
  };

  // Function to handle starting the timer
  const handleStart = async () => {
    try {
      await startTimer({ videoProcessId });
      setIsTimerRunning(true);
    } catch (error) {
      console.error('Error starting timer:', error);
    }
  };

  // Function to handle stopping the timer
  const handleStop = async () => {
    try {
      await stopTimer({ videoProcessId });
      setIsTimerRunning(false);
    } catch (error) {
      console.error('Error stopping timer:', error);
    }
  };

  return (
    <>
      <Button onClick={isTimerRunning ? handleStop : handleStart} extraClasses="btn-sm ml-3" extraStyles={{ width: "fit-content", height: "fit-content" }}>
          <span>{isTimerRunning ? 'Stop Timer' : 'Start Timer'}</span>
      </Button >
      <p class="mb-0">Total Time: {formatTime(accumulatedSeconds)}</p>
    </>
  );
};

// Helper function to format time in HH:MM:SS format
const formatTime = (totalSeconds) => {
  const hours   = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':');
};

export default EditorTimerComponent;