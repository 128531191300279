import React, { useEffect, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import { formatDetailedUtcDate } from "../../utils/app-utils";
import { useGetVideoJobDetailsQuery, } from "../api/apiSlice";
import { useGetCvMatchQuery } from "../api/snapshotsSlice";
import { useDeleteCvSnapshotMutation } from "../api/snapshotsSlice";
import {
    useEnqueueVideoMutation,
    useLazyGetAllReadyVideosForLinkingV2Query,
    useGetMoveRunStatusQuery,
    useLazyGetReadyVideoForLinkingByIdQuery,
    useMoveToFullMutation,
    useRefreshManualCollectedVideosMutation,
    useSendVideoMutation,
    useUnlinkVideoMutation,
    useUpdateEncodingJobIdMutation,
    useUpdateTrimMatchVideoJobIdMutation
} from "../api/collectedVideosSlice";
import { useAddNewMatchMutation, useEncodeVideoInstanceMutation, useGetAllPlayersQuery, useGetEventInstancesQuery, useSearchMatchesQuery } from "../api/completeMatchesSlice";
import { useTrimMatchVideoMutation } from "../api/matchesSlice";
import { selectIsAdmin, selectUser, selectIsCV } from "../auth/authSlice";
import {
    filterVideosByTitle,
    selectFilteredVideos,
    selectSelectedVideo,
    setSelectedVideo,
    selectSearchP1,
    selectSearchP2,
    selectSearchTournament,
    selectPageNumber,
    selectPageSize,
    selectTotalCount,
    selectCurrentPage,
    selectDetailedVideo,
    setVideos,
    setSearchP1,
    setSearchP2,
    setSearchTournament,
    setCurrentPage,
    setDetailedVideo
} from "./VideosLinkningSlice";
import PathConstants from "../../routes/pathConstants";
import { useUpdateMatchInstanceStatusMutation } from "../api/matchInstancesApislice";
import { useCreateSnapshotMutation } from "../api/cvSlice";
import useShowToastr from "../../custom-hooks/useShowToastr";
import VideoDetails from "../editorVideos/VideoDetails";
import { useGetCodersListQuery } from "../api/oktaSlice";
import { Tabs } from 'rsuite';
import VideosLinkingList from "./VideosLinkingList";
import ResponsivePagination from 'react-responsive-pagination';
import EditMatchData from "./EditMatchData";

import 'rsuite/dist/rsuite-no-reset.min.css';



export default function VideosLinkingPage() {
    const category = useParams().category;
    const [searchParams, setSearchParams] = useSearchParams();
    const [p1TimeoutHandle, setP1TimeoutHandle] = useState(null);
    const [p2TimeoutHandle, setP2TimeoutHandle] = useState(null);
    const [tournamentTimeoutHandle, setTournamentTimeoutHandle] = useState(null);
    const videoProcessId = searchParams.get('videoProcessId');

    const label = category === "ttv"
        ? "Tennis TV"
        : category === "wtv"
            ? "WTA TV"
            : category === "ctv"
                ? "Challenger TV"
                : category === "recording-team"
                    ? "Recording Team"
                    : category === "wtt"
                        ? "ITF"
                        : category === "tc"
                            ? "Tennis Channel"
                            : category === "espn"
                                ? "ESPN"
                                : category == "dropbox"
                                    ? "Dropbox"
                                    : category == "img"
                                        ? "IMG"
                                        : "DDSA";

    const dispatch = useDispatch();
    const filteredVideos = useSelector(selectFilteredVideos);
    const selectedVideo = useSelector(selectSelectedVideo);
    const searchP1 = useSelector(selectSearchP1);
    const searchP2 = useSelector(selectSearchP2);
    const searchTournament = useSelector(selectSearchTournament);
    const pageNumber = useSelector(selectPageNumber);
    const pageSize = useSelector(selectPageSize);
    const totalCount = useSelector(selectTotalCount);
    const detailedVideo = useSelector(selectDetailedVideo);
    const currentPage = useSelector(selectCurrentPage);

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isCV = useSelector(selectIsCV);
    const [isCVDataAvailable, setIsCVDataAvailable] = useState(false);
    const [snapshotId, setSnapshotId] = useState('');
    const [runId, setRunId] = useState(null);
    const [trimMatchVideoJobId, setMatchVideoTrimJobId] = useState(null);
    const [encodeJobId, setEncodeJobId] = useState(null);
    const [runSuccess, setRunSuccess] = useState(false);
    const [encodeSuccess, setEncodeSuccess] = useState(false);
    const [timeMatchVideoSuccess, setTrimMatchVideoSuccess] = useState(false);
    const { data: cvMatch, isLoading: cvMatchLoading, isSuccess: cvMatchSuccess, error: cvMatchError, status: cvMatchStatus } = useGetCvMatchQuery({ videoProcessId: detailedVideo?.id, snapshotId: snapshotId }, { skip: detailedVideo === null || snapshotId == '' });
    const { data: matches, isLoading: matchesLoading, isSuccess: matchesSuccess, error: matchesError, status: matchesStatus, refetch: refetchMatchesCallBack, isUninitialized: isSearchMatchesUnitialized } = useSearchMatchesQuery({
        p1Id: detailedVideo?.linked_metadata?.player1Id,
        p2Id: detailedVideo?.linked_metadata?.player2Id,
        eventInstanceId: detailedVideo?.linked_metadata?.eventInstanceId,
        roundId: detailedVideo?.linked_metadata?.roundId
    });
    const { data: moveStatusData, isLoading: moveStatusLoading, isSuccess: moveStatusSuccess, error: moveStatusError, status: moveStatusStatus, refetch: refreshRunStatus } = useGetMoveRunStatusQuery(runId, {
        skip: runId === null || runSuccess === true,
        pollingInterval: 3000
    });
    const { data: encodeJobDetails, isLoading: encodeJobDetailsLoading, isSuccess: encodeJobDetailsSuccess, error: encodeJobDetailsError, status: encodeJobDetailsStatus, refetch: refreshEncodeJobStatus } = useGetVideoJobDetailsQuery({ jobId: encodeJobId }, {
        skip: encodeJobId === undefined || encodeJobId === null || encodeSuccess === true,
        pollingInterval: 3000
    });
    const { data: matchVideoTrimDetails } = useGetVideoJobDetailsQuery({ jobId: trimMatchVideoJobId }, {
        skip: trimMatchVideoJobId === undefined || trimMatchVideoJobId === null || timeMatchVideoSuccess === true,
        pollingInterval: 3000
    });

    // const { data: videos, isLoading, error, isSuccess, status } = useGetAllReadyVideosForLinkingQuery({ category, isLinked: undefined }, { skip: category === undefined || category === null });

    const count = filteredVideos ? filteredVideos.length : 0;
    const [moveToFull, { isLoading: isMoving, isSuccess: isMoveSuccess, error: moveError, status: moveStatus }] = useMoveToFullMutation();
    const [addNewMatch, { isLoading: isAdding, isSuccess: isAddSuccess, error: addError, status: addStatus }] = useAddNewMatchMutation();
    const [encodeVideoInstance, { isLoading: isEncoding, isSuccess: isEncodeSuccess, error: encodeError, status: encodeStatus }] = useEncodeVideoInstanceMutation();
    const [trimMatchVideo, { isLoading: isTrimming, isSuccess: isTrimSuccess, error: trimError, status: trimStatus }] = useTrimMatchVideoMutation();
    const [updateEncodingJobId, { isLoading: isUpdatingJobId, isSuccess: isUpdateJobIdSuccess, error: updateJobIdError, status: updateJobIdStatus }] = useUpdateEncodingJobIdMutation();
    const [updateTrimMatchVideoJobId] = useUpdateTrimMatchVideoJobIdMutation();
    const [sendVideo, { isLoading: isSending, isSuccess: isSendSuccess, error: sendError, status: sendStatus }] = useSendVideoMutation();
    const { data: players, isLoading: playersLoading, isSuccess: playersSuccess, error: playersError, status: playersStatus } = useGetAllPlayersQuery();
    const { data: events, isLoading: eventsLoading, isSuccess: eventsSuccess, error: eventsError, status: eventsStatus } = useGetEventInstancesQuery();
    const [unlinkVideo, { isLoading: isUnlinking, isSuccess: isUnlinkSuccess, error: unlinkError, status: unlinkStatus }] = useUnlinkVideoMutation();
    const [enqueueVideo, { isLoading: isEnqueuing, isSuccess: isEnqueueSuccess, error: enqueueError, status: enqueueStatus }] = useEnqueueVideoMutation();
    const [refreshManualCollectedVideos, { isLoading: isRefreshing, isSuccess: isRefreshSuccess, error: refreshError, status: refreshStatus }] = useRefreshManualCollectedVideosMutation();
    const [deleteSnapshot, { isLoading: isDeleting, isSuccess: isDeleteSuccess, error: deleteError, status: deleteStatus }] = useDeleteCvSnapshotMutation();
    const [getVideoProcessById, { isLoading: isVideoDetailsLoading, isFetching: isVideoDetailsFetching }] = useLazyGetReadyVideoForLinkingByIdQuery();
    const [createSnapshot, { isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot, isLoading: creatingSnapshot }] = useCreateSnapshotMutation();

    const [getVideos, { isLoading: loadingVideos, isFetching: fetchingVideos, isError: error }] = useLazyGetAllReadyVideosForLinkingV2Query();


    const fetchVideos = async () => {
        var response = await getVideos(
            {
                category: category,
                isLinked: undefined,
                pageNumber: currentPage,
                pageSize: 10,
                searchP1: searchP1.length > 0 ? searchP1 : "",
                searchP2: searchP2.length > 0 ? searchP2 : "",
                searchTournament: searchTournament.length > 0 ? searchTournament : ""
            }
        );
        if (response.data) {
            dispatch(setVideos(response.data));
        }
    };

    useEffect(() => {
        fetchVideos();
    }, [searchP1, searchP2, searchTournament, currentPage, category]);

    const countPages = totalCount && !loadingVideos ? Math.ceil(totalCount / 10) : 0;

    const selectedVideoChangedHandler = async (selectedVideo) => {
        dispatch(setSelectedVideo(selectedVideo));
        setSearchParams({ videoProcessId: selectedVideo.id });
    }

    const searchP1TextChangedHandler = (event) => {
        if (p1TimeoutHandle) {
            clearTimeout(p1TimeoutHandle);
        }
        setP1TimeoutHandle(setTimeout(() => {
            dispatch(setSearchP1(event.target.value));
        }, 1000));
    };

    const searchP2TextChangedHandler = (event) => {
        if (p2TimeoutHandle) {
            clearTimeout(p2TimeoutHandle);
        }
        setP2TimeoutHandle(setTimeout(() => {
            dispatch(setSearchP2(event.target.value));
        }, 1000));
    };

    const searchTournamentTextChangedHandler = (event) => {
        if (tournamentTimeoutHandle) {
            clearTimeout(tournamentTimeoutHandle);
        }
        setTournamentTimeoutHandle(setTimeout(() => {
            dispatch(setSearchTournament(event.target.value));
        }, 1000));
    };

    const getMatchDataTypeName = (value) => {
        const matchDataTypes = {
            0: 'SM',
            1: 'HC',
            2: 'GS',
            3: 'MT',
            4: 'VID',
            5: 'HE',
            6: 'GD',
            7: 'HD',
            8: 'HE2',
            9: 'BGVID',
            10: 'BG1',
            11: 'CV',
            12: 'CVSR',
            13: 'CVGS',
            14: 'TVID'
        };
    
        return matchDataTypes[value] || 'Unknown';
    };

    const { data: codersList, isLoading: loadingCoders } = useGetCodersListQuery();


    const codersOptions = codersList ? [
        { label: 'None', value: '' },
        ...codersList.map(coder => ({ label: coder.name, value: coder.name }))
    ] : null;

    useShowToastr({ isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot, title: "Create Snapshot", successMsg: "Snapshot created successfully", errorMsg: "Snapshot creation failed" });

    const [updateMatchInstanceStatus, { isLoading: isUpdating, isSuccess: isUpdateSuccess, error: updateError, status: updateStatus }] = useUpdateMatchInstanceStatusMutation();

    const [p1, setP1] = useState(null);
    const [p2, setP2] = useState(null);
    const [event, setEvent] = useState(null);
    const [winner, setWinner] = useState(null);
    const [cvEnvironment, setCvEnvironment] = useState("prod");
    const [screenCoordinates, setScreenCoordinates] = useState(false);

    const matchRoundOptions = [
        { value: 0, label: '1st Round' },
        { value: 1, label: '2nd Round' },
        { value: 2, label: '3rd Round' },
        { value: 3, label: '4th Round' },
        { value: 7, label: 'Bronze' },
        { value: 6, label: 'Final Round' },
        { value: 100, label: 'N/A' },
        { value: 8, label: 'Pre-qualifying' },
        { value: 11, label: 'Qualifying' },
        { value: 9, label: 'Qualifying-1' },
        { value: 10, label: 'Qualifying-2' },
        { value: 4, label: 'Quarterfinals Round' },
        { value: 12, label: 'Robin' },
        { value: 13, label: 'Rubber 1' },
        { value: 14, label: 'Rubber 2' },
        { value: 15, label: 'Rubber 3' },
        { value: 16, label: 'Rubber 4' },
        { value: 17, label: 'Rubber 5' },
        { value: 5, label: 'Semifinals Round' },
    ];

    useEffect(() => {
        if (videoProcessId) {
            getVideoProcessById(videoProcessId).then((response) => {
                dispatch(setDetailedVideo(response.data));
            });
        } else {
            dispatch(setDetailedVideo(null));
        }
    }, [videoProcessId]);

    useEffect(() => {
        if (moveStatusSuccess) {
            if (moveStatusData.status === "Succeeded" || moveStatusData.status === "404") {
                refetchMatchesCallBack();
                setRunSuccess(true);
            }
        }
    }, [moveStatusData, moveStatusLoading, moveStatusSuccess, moveStatusError, moveStatusStatus]);


    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (isEnqueueSuccess) {
            toastr.success("Re-downlaod request posted successfully", "Re-Download Video");
        }
        else if (enqueueError) {
            toastr.error("Re-download request failed to submit", "Re-Download Video");
        }
    }, [isEnqueueSuccess, enqueueError, enqueueStatus]);


    useEffect(() => {
        if (cvMatchSuccess) {
        }
    }, [cvMatch]);


    useEffect(() => {
        if (matchesSuccess) {
            if (matches.length > 0 && detailedVideo) {
                let vidInstance = matches.find((x) => x.matchInstances.find((y) => y.matchDataTypeId === 4) !== undefined);
                setIsCVDataAvailable(detailedVideo.player1Id !== null
                    && detailedVideo.player2Id !== null
                    && detailedVideo.eventInstanceId !== null
                    && detailedVideo.recent_cv_status?.status === "FINISHED"
                    && vidInstance !== undefined);
            }
        } else {
            setIsCVDataAvailable(false);
        }
    }, [matches, matchesSuccess, matchesError, detailedVideo]);

    useEffect(() => {
        if (detailedVideo) {
            if (detailedVideo.videoMoveRunId !== null) {
                setRunId(detailedVideo.videoMoveRunId);
            }
            else {
                setRunId(null);
            }

            if (detailedVideo.videoEncodingJobId && detailedVideo.videoEncodingJobId !== null) {
                setEncodeJobId(detailedVideo.videoEncodingJobId);
            }
            else {
                setEncodeJobId(null);
            }
            if (detailedVideo.trimMatchVideoJobId && detailedVideo.trimMatchVideoJobId !== null) {
                setMatchVideoTrimJobId(detailedVideo.trimMatchVideoJobId);
            }
            else {
                setMatchVideoTrimJobId(null);
            }
            setSnapshotId(detailedVideo.snapshots?.length > 0 ? detailedVideo.snapshots[detailedVideo.snapshots.length - 1].snapshotId : '');

            if (detailedVideo.isLinked === true && players) {
                const p1 = players.find(x => x.playerId === detailedVideo.linked_metadata.player1Id);
                const p2 = players.find(x => x.playerId === detailedVideo.linked_metadata.player2Id);
                const winner = players.find(x => x.playerId === detailedVideo.linked_metadata.winnerId);
                setP1(p1);
                setP2(p2);
                setWinner(winner);
            } else {
                setP1(null);
                setP2(null);
                setWinner(null);
            }

            if (detailedVideo.isLinked === true && events) {
                const event = events.find(x => x.eventInstanceId === detailedVideo.linked_metadata.eventInstanceId);
                setEvent(event);
            } else {
                setEvent(null);
            }

        } else {
            setRunId(null);
            setEncodeJobId(null);
            setSnapshotId('');
            setIsCVDataAvailable(false);
        }
    }, [detailedVideo]);

    useEffect(() => {
        if (encodeJobDetailsSuccess) {
            if (encodeJobDetails.result === "Success") {
                setEncodeSuccess(true);
            }
        }
    }, [encodeJobDetails]);

    useEffect(() => {
        if (matchVideoTrimDetails) {
            if (matchVideoTrimDetails.result === "Success") {
                setTrimMatchVideoSuccess(true);
            }
        }
    }, [matchVideoTrimDetails]);

    useEffect(() => {
        if (playersSuccess) {
            if (detailedVideo && detailedVideo.isLinked === true) {
                const p1 = players.find(x => x.playerId === detailedVideo.linked_metadata.player1Id);
                const p2 = players.find(x => x.playerId === detailedVideo.linked_metadata.player2Id);
                const winner = players.find(x => x.playerId === detailedVideo.linked_metadata.winnerId);
                setP1(p1);
                setP2(p2);
                setWinner(winner);
            }
        }
    }, [players]);

    useEffect(() => {
        if (events && eventsStatus) {
            if (detailedVideo && detailedVideo.isLinked === true) {
                const event = events.find(x => x.eventInstanceId === detailedVideo.linked_metadata.eventInstanceId);
                setEvent(event);
            }
        }
    }, [events]);

    const downloadSnapshotHandler = () => {
        let url = `${process.env.REACT_APP_API_URL}/videos/${detailedVideo.id}/snapshots/download/${snapshotId}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    if (!user) return <Spinner />;
    else if (!isAdmin && !isCV) return <NotAuthorizedPage />;
    if (loadingVideos) return <Spinner />;
    if (error) return <div>
        {error.status} {JSON.stringify(error.error)}
    </div>;

    return (
        <div className="row">
            <div className="col-4 mb-3">
                <div className="card mt-1 min-vh-75">
                    <div className="card-header">
                        <div className="row justify-content-between">
                            <h5>{label} Videos ({totalCount})</h5>
                            {(label === "Dropbox" || label === "Recording Team") && (
                                <button className="btn btn-primary mr-1 mb-1 fs--1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        refreshManualCollectedVideos().then((response) => {
                                            toastr.options.positionClass = "toast-bottom-right";
                                            toastr.success("Refresh request posted successfully, Please refresh entire page after 1 min", "Refresh Videos");
                                        }).catch((error) => {
                                            toastr.options.positionClass = "toast-bottom-right";
                                            toastr.error("Refresh request failed to submit", "Refresh Videos");
                                        });
                                    }}>
                                    Refresh
                                </button>)
                            }
                        </div>
                        <div className="col-12 mt-1">
                            <input type="text" className="form-control" placeholder="Search by player 1" onChange={searchP1TextChangedHandler} />
                            <input type="text" className="form-control" placeholder="Search by player 2" onChange={searchP2TextChangedHandler} />
                            <input type="text" className="form-control" placeholder="Search by tournament" onChange={searchTournamentTextChangedHandler} />
                        </div>
                    </div>
                    <div className="card-body p-0 scrollbar perfect-scrollbar">
                        <div style={{ width: "100%" }}>
                            <div className=" my-3">
                                <ResponsivePagination
                                    current={currentPage}
                                    total={countPages}
                                    onPageChange={(page) => dispatch(setCurrentPage(page))}
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                />
                            </div>
                            <div className="col-12 mt-1">
                                <input type="text" className="form-control" placeholder="Search by name" onChange={(x) => {
                                    dispatch(filterVideosByTitle(x.target.value));
                                }} />
                            </div>
                            <VideosLinkingList videos={filteredVideos} count={!loadingVideos ? totalCount : 0} isLoading={loadingVideos || fetchingVideos} selectedVideoId={selectedVideo?.id} onSelectedVideoChange={selectedVideoChangedHandler} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-8 mb-3">
                {(isVideoDetailsLoading || isVideoDetailsFetching) && <Spinner />}
                {!isVideoDetailsLoading && !isVideoDetailsFetching && detailedVideo && (
                    <div>
                        <div className="card mt-1">
                            <div className="card-header d-flex align-items-center">
                                <h5>{detailedVideo.title}</h5>
                                <button className="btn btn-primary ml-3 p-1" onClick={() => getVideoProcessById(detailedVideo.id).then((response) => {
                                    dispatch(setDetailedVideo(response.data));
                                })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                    </svg>
                                </button>
                            </div>
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Id</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <strong>{detailedVideo.id}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <CopyToClipboard text={detailedVideo.source_url}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Source URL <span className="far fa-copy"></span></button>
                                </CopyToClipboard>
                                <CopyToClipboard text={detailedVideo.browserURL}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Browser URL <span className="far fa-copy"></span></button>
                                </CopyToClipboard>
                                <CopyToClipboard text={detailedVideo.downloadURL}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Download URL <span className="far fa-copy"></span></button>
                                </CopyToClipboard>
                                {/* <button className="btn btn-primary mr-1 mb-1 fs--1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        enqueueVideo({ id: detailedVideo.metadata.id, category: category });
                                    }}>
                                    Re-Download Video
                                </button> */}
                                {matches && detailedVideo && matches.map(match => (
                                    match.matchInstances.map(instance => (
                                        (instance.matchDataTypeId === 14 && instance.matchInstanceStatusId === 40) && (
                                            <CopyToClipboard key={instance.matchInstanceId} text={`https://gsa-video.azureedge.net/full/${match.eventInstanceId}/${match.matchId}/${instance.matchInstanceId}.mp4?sv=2022-11-02&ss=b&srt=sco&sp=rlx&se=2025-12-30T23:35:54Z&st=2023-12-28T15:35:54Z&spr=https&sig=s1UPkZp2UFuQK1vjzjYjQSPDgZ73gh%2FbsQopO53mzxo%3D`}>
                                                <button className="btn btn-success mr-1 mb-1 fs--1" type="button">
                                                    Trimmed Video URL <span className="far fa-copy"></span>
                                                </button>
                                            </CopyToClipboard>
                                        )
                                    ))
                                ))}
                                <div className="row mt-2 ml-0 align-items-center">
                                    <div className="btn-group mr-2">
                                        <button className="btn btn-sm dropdown-toggle btn-light" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{cvEnvironment === "dev" ? "CV Development" : "CV Production"}
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" onClick={() => setCvEnvironment("dev")}>CV Development</a>
                                            <a className="dropdown-item" onClick={() => setCvEnvironment("prod")}>CV Production</a>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-sm fs--1"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            sendVideo({ videoId: detailedVideo.id, env: cvEnvironment, screenCoordinates: screenCoordinates });
                                        }}>
                                        Send Video To CV
                                    </button>
                                    <div className="form-check ml-2">
                                        <input className="form-check-input" type="checkbox" id="screenCoordinates" checked={screenCoordinates} onChange={(e) => setScreenCoordinates(e.target.checked)} />
                                        <label className="form-check-label" htmlFor="screenCoordinates">
                                            Screen Coordinates
                                        </label>
                                    </div>
                                    <div className="ml-auto">
                                        <EditMatchData videoId={detailedVideo.id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tabs className="mt-3 mb-1" defaultActiveKey="1" appearance="subtle">
                            <Tabs.Tab eventKey="1" title="Match Data">
                                {!isVideoDetailsLoading && matches?.length > 0 && (
                                    matches.map((match) => {
                                        return (
                                            <div key={match.matchId} className="card mt-1">
                                                <div className="card-header">
                                                    <h5>{match.name} - {match.matchId}</h5>
                                                    {detailedVideo.isLinked !== undefined && !detailedVideo.isLinked &&
                                                        <div className="d-flex row mt-2">
                                                            <span className="text-danger">Waiting for linking data to be updated the matches below may change.</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="card-body p-0">
                                                    <table className="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Id</th>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">File Name</th>
                                                                <th scope="col">File Status</th>
                                                                <th scope="col">Snapshot Id</th>
                                                                <th scope="col">Trimmed Using</th>
                                                                <th scope="col">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {match.matchInstances.map((instance) => {
                                                                return (
                                                                    <tr key={instance.matchInstanceId}>
                                                                        <td>{instance.matchInstanceId}</td>
                                                                        <td>{getMatchDataTypeName(instance.matchDataTypeId)}</td>
                                                                        <td>
                                                                            {instance.files[0].fileName}
                                                                        </td>
                                                                        <td>{instance.matchInstanceStatusId}
                                                                            <br />
                                                                            {runId && moveStatusSuccess && instance.matchDataTypeId === 4 && (
                                                                                <strong>Moving {moveStatusData.status}</strong>
                                                                            )}
                                                                            {encodeJobId && encodeJobDetailsSuccess && instance.matchDataTypeId === 4 && (
                                                                                <strong>Encoding {encodeJobDetails.status}</strong>
                                                                            )}
                                                                            {trimMatchVideoJobId && matchVideoTrimDetails && instance.matchDataTypeId === 14 && (
                                                                                <strong>Trimming {matchVideoTrimDetails.status} {matchVideoTrimDetails.result}</strong>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {instance.snapshotId}
                                                                        </td>
                                                                        <td>
                                                                            {instance.isTrimmed ? "Yes" : ""}
                                                                        </td>
                                                                        <td>
                                                                            {(instance.matchDataTypeId === 4 || instance.matchDataTypeId === 9) && (
                                                                                <button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    e.nativeEvent.stopImmediatePropagation();
                                                                                    let videoBlobPath =
                                                                                        detailedVideo.source_url
                                                                                            .replace(
                                                                                                "https://gsavideo.blob.core.windows.net/collected-videos/",
                                                                                                "").replace(
                                                                                                    "https://gsavideo.blob.core.windows.net/recording-team/",
                                                                                                    "");
                                                                                    moveToFull({
                                                                                        videoId: detailedVideo.id,
                                                                                        videoBlobPath: videoBlobPath,
                                                                                        destinationPath: `${match.eventInstanceId}/${match.matchId}/${instance.matchInstanceId}.mp4`,
                                                                                        matchInstanceId: instance.matchInstanceId,
                                                                                    }).then((response) => {
                                                                                        setRunId(response.data.runId);
                                                                                    });
                                                                                }}>Move</button>
                                                                            )}
                                                                            {(instance.matchDataTypeId === 4 || instance.matchDataTypeId === 9) && instance.matchInstanceStatusId === 40 && (
                                                                                <button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    e.nativeEvent.stopImmediatePropagation();
                                                                                    trimMatchVideo(match.matchId).then((response) => {
                                                                                        if (response.error) {
                                                                                            toastr.options.positionClass = "toast-bottom-right";
                                                                                            toastr.error("Trim request failed to submit " + response.error.data, "Trim Video");
                                                                                        }
                                                                                        else {
                                                                                            setMatchVideoTrimJobId(response.data);
                                                                                            updateTrimMatchVideoJobId({ id: detailedVideo.id, jobId: response.data });
                                                                                            toastr.options.positionClass = "toast-bottom-right";
                                                                                            toastr.success("Trim request submitted successfully", "Trim Video");

                                                                                            refetchMatchesCallBack();
                                                                                        }
                                                                                    });
                                                                                }}>Trim</button>
                                                                            )}
                                                                            {(instance.matchDataTypeId === 14) && instance.matchInstanceStatusId === 40 && (
                                                                                <CopyToClipboard text={`https://gsa-video.azureedge.net/full/${match.eventInstanceId}/${match.matchId}/${instance.matchInstanceId}.mp4?sv=2022-11-02&ss=b&srt=sco&sp=rlx&se=2025-12-30T23:35:54Z&st=2023-12-28T15:35:54Z&spr=https&sig=s1UPkZp2UFuQK1vjzjYjQSPDgZ73gh%2FbsQopO53mzxo%3D`}>
                                                                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Browser URL</button>
                                                                                </CopyToClipboard>
                                                                            )}
                                                                            {(instance.matchDataTypeId === 4 || instance.matchDataTypeId === 9) && (encodeJobId === null) && instance.matchInstanceStatusId === 40 && (<button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                e.nativeEvent.stopImmediatePropagation();
                                                                                encodeVideoInstance({
                                                                                    matchId: match.matchId
                                                                                }).then((response) => {
                                                                                    setEncodeJobId(response.data);
                                                                                    updateEncodingJobId({ id: detailedVideo.id, jobId: response.data });
                                                                                });
                                                                            }}>Encode</button>
                                                                            )}
                                                                            {(instance.matchDataTypeId === 8) && instance.matchInstanceStatusId === 40 && (<button className="btn btn-primary btn-sm mb-1 mr-1" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                e.nativeEvent.stopImmediatePropagation();
                                                                                updateMatchInstanceStatus({
                                                                                    matchId: match.matchId,
                                                                                    matchInstanceId: instance.matchInstanceId,
                                                                                    statusId: 30
                                                                                }).then((response) => {
                                                                                    refetchMatchesCallBack();
                                                                                });
                                                                            }}>QA</button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td>
                                                                    <button className="btn btn-sm btn-warning my-3 ml-2 text-nowrap" disabled={!detailedVideo.isLinked} onClick={() => {
                                                                        unlinkVideo(detailedVideo.id).then(() => {
                                                                            dispatch(setDetailedVideo({ ...detailedVideo, isLinked: false }))
                                                                        });
                                                                    }}>
                                                                        Mark Not Linked
                                                                    </button>
                                                                    {!detailedVideo.isLinked &&
                                                                        <button className="btn btn-primary btn-sm ml-3" onClick={() => {
                                                                            let url = `${window.location.origin}/codersvideoslinking/${category}?videoProcessId=${detailedVideo.id}`;
                                                                            window.open(url, "_blank", "noopener,noreferrer");
                                                                        }}>Go to linking page</button>}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div className="card-footer">
                                                    <button className="btn btn-primary mr-1"
                                                        disabled={
                                                            detailedVideo.player1Id === null
                                                            || detailedVideo.player2Id === null
                                                            || detailedVideo.eventInstanceId === null
                                                            || match.matchInstances.find((x) => x.matchDataTypeId === 4) === undefined
                                                        } onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            if (!isSearchMatchesUnitialized) {
                                                                refetchMatchesCallBack();
                                                            }
                                                        }}>
                                                        Refresh
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}

                                {!isVideoDetailsLoading && matches?.length === 0 && detailedVideo && detailedVideo.isLinked === true && (
                                    <div className="card mt-1">
                                        <div className="card-header">
                                            <h5>Create New Match</h5>
                                        </div>
                                        <div className="card-body p-0">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Property</th>
                                                        <th scope="col">Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Player 1</td>
                                                        <td>{p1?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Player 2</td>
                                                        <td>{p2?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Event</td>
                                                        <td>{event?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Round</td>
                                                        <td>{matchRoundOptions.find(x => x.value === detailedVideo.linked_metadata.roundId).label}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Winner</td>
                                                        <td>{winner?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Result</td>
                                                        <td>{detailedVideo.linked_metadata.result}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Result Type</td>
                                                        <td>{detailedVideo.linked_metadata.resultType}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="card-footer">
                                            <button className="btn btn-primary"
                                                disabled={
                                                    detailedVideo.linked_metadata.winnerId === null
                                                    || detailedVideo.linked_metadata.result === null || detailedVideo.linked_metadata.result === ""
                                                    || detailedVideo.linked_metadata.resultType === null
                                                    || detailedVideo.linked_metadata.matchType === null
                                                    || detailedVideo.linked_metadata.player1Id === null
                                                    || detailedVideo.linked_metadata.player2Id === null
                                                    || detailedVideo.linked_metadata.eventInstanceId === null
                                                } onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    let imgBatch = null;
                                                    if (detailedVideo.img_metadata){
                                                        imgBatch = detailedVideo.img_metadata.batch;
                                                    }
                                                    addNewMatch({
                                                        videoId : detailedVideo.id,
                                                        playerId1: detailedVideo.linked_metadata.player1Id,
                                                        playerId2: detailedVideo.linked_metadata.player2Id,
                                                        winnerId: detailedVideo.linked_metadata.winnerId,
                                                        eventInstanceId: detailedVideo.linked_metadata.eventInstanceId,
                                                        roundId: detailedVideo.linked_metadata.roundId,
                                                        result: detailedVideo.linked_metadata.result,
                                                        resultType: detailedVideo.linked_metadata.resultType,
                                                        matchType: detailedVideo.linked_metadata.matchType,
                                                        MetadataId: detailedVideo.metadata.id,
                                                        iMGBatch: imgBatch
                                                    }).then((response) => {
                                                        refetchMatchesCallBack();
                                                    });
                                                }}>Add New Match</button>
                                            <button className="btn btn-sm btn-warning my-3 ml-3 text-nowrap" onClick={() => {
                                                unlinkVideo(detailedVideo.id).then(() => {
                                                    dispatch(setDetailedVideo({ ...detailedVideo, isLinked: false }))
                                                });
                                            }}>
                                                Mark Not Linked
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {!isVideoDetailsLoading && matches?.length === 0 && detailedVideo && detailedVideo.isLinked === false && (
                                    <div className="card mt-1">
                                        <div className="card-body d-flex row align-items-center">
                                            <h5>Please complete linking first to create new match</h5>
                                            <button className="btn btn-primary ml-3" onClick={() => {
                                                let url = `${window.location.origin}/codersvideoslinking/${category}?videoProcessId=${detailedVideo.id}`;
                                                window.open(url, "_blank", "noopener,noreferrer");
                                            }}>Go to linking page</button>
                                        </div>
                                    </div>
                                )}
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="CV Logs">
                                {!isVideoDetailsLoading && detailedVideo !== null && detailedVideo.cv_status_history?.length > 0 && (
                                    <div className="card mt-1">
                                        <div className="card-header">
                                            <div className="row justify-content-between">
                                                <div className="col-auto">
                                                    <h5>CV Logs ({detailedVideo.cv_env === "dev" ? "Development" : "Production"}) {detailedVideo.cv_version ? `(Version ${detailedVideo.cv_version})` : ""}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Message</th>
                                                            <th scope="col">Created On</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {detailedVideo.cv_status_history.map((status) => {
                                                            return (
                                                                <tr key={status.receivedAt}>
                                                                    <td>{status.status}</td>
                                                                    <td>{status.message}</td>
                                                                    <td>{formatDetailedUtcDate(status.receivedAt)}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="3" title="Snapshots">
                                {!isVideoDetailsLoading && detailedVideo !== null && detailedVideo.snapshots.length != 0 && (
                                    <div className="card mt-1">
                                        <div className="card-header">
                                            <div className="row justify-content-between">
                                                <div className="row ml-2 align-items-center">
                                                    <h5 className="mr-2 ">CV Data</h5>
                                                    <div className='d-flex row ml-auto'>
                                                        <span style={{ height: "fit-content" }} className="badge badge-pill badge-dark mr-2">{!detailedVideo.editorTracker.assignee || detailedVideo.editorTracker.assignee == "" ? "Not Assigned" : detailedVideo.editorTracker.assignee}</span>
                                                        <span className={`badge badge-pill badge-warning mr-2`}>{detailedVideo.editorTracker.status}</span>
                                                        <span className={`badge badge-pill badge-secondary`}>{detailedVideo.editorTracker.typeOfCoding}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="mr-2">
                                                        <select id="snapshotsSelect" className="form-control" value={snapshotId} onChange={(x) => {
                                                            if (x.target.value === 'Select Snapshot') {
                                                                setSnapshotId('');
                                                            } else {
                                                                setSnapshotId(x.target.value);
                                                            }
                                                        }}>
                                                            <option value={null}>Select Snapshot</option>
                                                            {detailedVideo.snapshots.map((option) => {
                                                                return (
                                                                    <option key={option.snapshotId} value={option.snapshotId}>{option.snapshotId}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    {(snapshotId == '' || true) && <div>
                                                        <button type="button" className="btn btn-primary mr-1" disabled={creatingSnapshot} onClick={() => createSnapshot(detailedVideo.id).then(response => {
                                                            getVideoProcessById(detailedVideo.id).then((response) => {
                                                                dispatch(setDetailedVideo(response.data));
                                                            });
                                                            setSnapshotId('');
                                                        })}>
                                                            {creatingSnapshot ? "Creating..." : "Create CV Snapshot"}
                                                        </button>
                                                    </div>}
                                                    {detailedVideo.snapshots?.length > 0 && snapshotId != '' && <div>
                                                        <a className="btn btn-primary mr-1" href={`${window.location.origin}/${PathConstants.EDITOR}?videoProcessId=${detailedVideo.id}&snapshot=${snapshotId}&isImg=${detailedVideo.source === "img" || detailedVideo.metadata?.location === "IMG Academy"}`} target="_blank" rel="noreferrer">
                                                            Open Editor
                                                        </a>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {cvMatchLoading && (<Spinner />)}
                                                {cvMatchSuccess && !cvMatchLoading && cvMatch && snapshotId !== '' && (
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Property</th>
                                                                <th scope="col">Value</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Created By</td>
                                                                <td>{cvMatch?.createdBy}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Created On</td>
                                                                <td>{formatDetailedUtcDate(cvMatch?.createdAt)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Points</td>
                                                                <td>{cvMatch.points.length}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Failed Points While Parsing</td>
                                                                <td>{cvMatch.failedParserCount}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Needs Review</td>
                                                                <td>{cvMatch.points.filter((p) => p.needsReview).length}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot >
                                                            <tr>
                                                                <td>
                                                                    <div className="mt-3">
                                                                        <button className="btn btn-primary mr-1"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                e.nativeEvent.stopImmediatePropagation();
                                                                                downloadSnapshotHandler();
                                                                            }}>
                                                                            Download Snapshot
                                                                        </button>
                                                                        <button className="btn btn-danger mr-1"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                e.nativeEvent.stopImmediatePropagation();
                                                                                deleteSnapshot({ videoProcessId: detailedVideo.id, snapshotId: snapshotId }).then(response => {
                                                                                    getVideoProcessById(detailedVideo.id).then((response) => {
                                                                                        dispatch(setDetailedVideo(response.data));
                                                                                    });
                                                                                    setSnapshotId('');
                                                                                });
                                                                            }}>
                                                                            Delete Snapshot
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="4" title="Coding Editor">
                                {!isVideoDetailsLoading && detailedVideo !== null && detailedVideo.cv_status_history?.length > 0 && (
                                    <VideoDetails key={detailedVideo.id} video={detailedVideo} refetchVideos={null} setSelectedVideo={(detailedVideo) => dispatch(setDetailedVideo(detailedVideo))} codersOptions={codersOptions} isSimple={true} />
                                )}
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                )}
            </div>
        </div >
    );
}