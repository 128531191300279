import { useSelector, useDispatch } from "react-redux";
import { selectDetailedVideo, setDetailedVideo } from "./VideosLinkningSlice";
import { speedUnitOptions, tieBreakFinalSetOptions, typeOfScoringOptions, matchFormatsOptions, playerHandednessOptions, disableServeSpeedsOptions } from "../editor/Utils/options";
import ViewButton from "../../UI/Buttons/ViewButton";
import ButtonGroup from "../../components/ButtonGroup";
import { isEmptyField } from "../editor/Utils/shotUtils";
import useShowToastr from "../../custom-hooks/useShowToastr";
import { useUpdateExtendedMatchDetailsMutation } from "../api/collectedVideosSlice";

const EditMatchData = ({ videoId }) => {
    const detailedVideo = useSelector(selectDetailedVideo);
    const dispatch = useDispatch();

    const [updateExtendedMatchDetails, { isLoading, isSuccess, isError }] = useUpdateExtendedMatchDetailsMutation();

    useShowToastr({
        isSuccess: isSuccess,
        isError: isError,
        title: "Update Snapshot Metadata",
        successMsg: "Snapshot metadata updated successfully",
        errorMsg: "Failed to update snapshot metadata",
        modalId: "matchDetailsModal",
    });

    const optionChangedHandler = (field, value) => {
        const updatedExtendedMatchDetails = { ...detailedVideo.extendedMatchDetails, [field]: value };
        dispatch(setDetailedVideo({ ...detailedVideo, extendedMatchDetails: updatedExtendedMatchDetails }));
    }

    const matchDetailsClickHandler = () => {
        $("#matchDetailsModal").modal("show");
    }

    return (
        <div className='row align-items-center'>
            <ViewButton onClick={matchDetailsClickHandler} extraClasses="btn-sm ml-3" extraStyles={{ width: "fit-content", height: "fit-content" }} isDisabled={false}>
                <span className="ml-2">Match Details</span>
            </ViewButton >
            <div
                className="modal fade"
                id="matchDetailsModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="matchDetailsModalLabel"
                aria-hidden="true"
            >
                <div style={{ minWidth: "40%" }} className="modal-dialog" role="document">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="matchDetailsModalLabel">
                                Match Details
                            </h5>
                        </div>
                        <div style={{ maxHeight: "70vh" }} className="modal-body">

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.speedUnit, speedUnitOptions) ? "text-danger" : ""}`} htmlFor="speedUnit">Speed Unit</label>
                                <ButtonGroup field="speedUnit" value={detailedVideo.extendedMatchDetails?.speedUnit} options={speedUnitOptions} onOptionChanged={optionChangedHandler} />
                            </div>

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.tieBreakFinalSet, tieBreakFinalSetOptions) ? "text-danger" : ""}`} htmlFor="tieBreakFinalSet">Tie Break Final Set</label>
                                <ButtonGroup field="tieBreakFinalSet" value={detailedVideo.extendedMatchDetails?.tieBreakFinalSet} options={tieBreakFinalSetOptions} onOptionChanged={optionChangedHandler} />
                            </div>

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.typeOfScoring, typeOfScoringOptions) ? "text-danger" : ""}`} htmlFor="typeOfScoring">Type Of Scoring</label>
                                <ButtonGroup field="typeOfScoring" value={detailedVideo.extendedMatchDetails?.typeOfScoring} options={typeOfScoringOptions} onOptionChanged={optionChangedHandler} />
                            </div>
                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.player1Handedness, playerHandednessOptions) ? "text-danger" : ""}`} htmlFor="player1Handedness">Player 1 Handedness</label>
                                <ButtonGroup field="player1Handedness" value={detailedVideo.extendedMatchDetails?.player1Handedness} options={playerHandednessOptions} onOptionChanged={optionChangedHandler} />
                            </div>

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.player2Handedness, playerHandednessOptions) ? "text-danger" : ""}`} htmlFor="player2Handedness">Player 2 Handedness</label>
                                <ButtonGroup field="player2Handedness" value={detailedVideo.extendedMatchDetails?.player2Handedness} options={playerHandednessOptions} onOptionChanged={optionChangedHandler} />
                            </div>
                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.matchFormat, matchFormatsOptions) ? "text-danger" : ""}`} htmlFor="matchFormat">Match Format</label>
                                <select className="form-control" id="matchFormat" value={detailedVideo.extendedMatchDetails?.matchFormat} onChange={(e) => optionChangedHandler("matchFormat", e.target.value)}>
                                    {matchFormatsOptions.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                                </select>
                            </div>
                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(detailedVideo.extendedMatchDetails?.disableServeSpeeds, disableServeSpeedsOptions) ? "text-danger" : ""}`} htmlFor="disableServeSpeeds">Disable Serve Speeds</label>
                                <ButtonGroup field="disableServeSpeeds" value={detailedVideo.extendedMatchDetails?.disableServeSpeeds} options={disableServeSpeedsOptions} onOptionChanged={optionChangedHandler} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-warning"
                                disabled={isLoading}
                                onClick={() => updateExtendedMatchDetails({ videoId, extendedMatchDetails: detailedVideo.extendedMatchDetails })}
                            >
                                {isLoading ? "Updating..." : "Update"}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMatchData;