import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  videos: [],
  selectedVideo: null,
  detailedVideo: null,
  filteredVideos: [],
  searchP1: "",
  searchP2: "",
  searchTournament: "",
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  currentPage: 1
};

const videosLinkingCodersSlice = createSlice({
  name: "videosLinkingCoders",
  initialState,
  reducers: {
    setVideos(state, action) {
      state.videos = action.payload.videos;
      state.filteredVideos = action.payload.videos;
      state.totalCount = action.payload.totalCount;
    },
    setSelectedVideo(state, action) {
      state.selectedVideo = action.payload;
    },
    setDetailedVideo(state, action) {
      state.detailedVideo = action.payload;
    },
    setSearchP1(state, action) {
      state.searchP1 = action.payload;
    },
    setSearchP2(state, action) {
      state.searchP2 = action.payload;
    },
    setSearchTournament(state, action) {
      state.searchTournament = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    reset(state) {
      state.videos = [];
      state.selectedVideo = null;
      state.filteredVideos = [];
    },
    filterVideosByTitle(state, action) {
      const keywords = action.payload.split(" ");
      const result = state.videos.filter((video) => {
        const title = video.title.toLowerCase();
        return keywords.every((keyword) => title.includes(keyword.toLowerCase()));
      });

      state.filteredVideos = result;
      state.videos.forEach((video) => (video.isFiltered = false));
      state.videos.forEach((video) => {
        if (result.some((filteredVideo) => filteredVideo.id === video.id)) {
          video.isFiltered = true;
        }
      });
    },
    updateVideoMetadata(state, action) {
      let video = { ...state.detailedVideo };
      Object.assign(video.linked_metadata, action.payload);
      state.detailedVideo = video;
    },
    removeVideo(state, action) {
      const { id } = action.payload;
      let video = state.videos.find((video) => video.id === id);
      if (video) {
        state.videos = state.videos.filter((video) => video.id !== id);
        state.filteredVideos = state.filteredVideos.filter((video) => video.id !== id);
      }
    }
  },
});

export const {
  setVideos,
  setSelectedVideo,
  setDetailedVideo,
  reset,
  filterVideosByTitle,
  updateVideoMetadata,
  removeVideo,
  setSearchP1,
  setSearchP2,
  setSearchTournament,
  setPageNumber,
  setPageSize,
  setTotalCount,
  setCurrentPage
} = videosLinkingCodersSlice.actions;

export const selectVideos = (state) => state.videosLinkingCoders.videos;
export const selectSelectedVideo = (state) => state.videosLinkingCoders.selectedVideo;
export const selectFilteredVideos = (state) => state.videosLinkingCoders.filteredVideos;
export const selectSearchP1 = (state) => state.videosLinkingCoders.searchP1;
export const selectSearchP2 = (state) => state.videosLinkingCoders.searchP2;
export const selectSearchTournament = (state) => state.videosLinkingCoders.searchTournament;
export const selectPageNumber = (state) => state.videosLinkingCoders.pageNumber;
export const selectPageSize = (state) => state.videosLinkingCoders.pageSize;
export const selectTotalCount = (state) => state.videosLinkingCoders.totalCount;
export const selectCurrentPage = (state) => state.videosLinkingCoders.currentPage;
export const selectDetailedVideo = (state) => state.videosLinkingCoders.detailedVideo;
export default videosLinkingCodersSlice.reducer;